
import HalfBanner from "../components/HalfBanner";
import KariyerCardList from "../components/KariyerCard";
import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";
import { Helmet } from 'react-helmet';

const Kariyer = () => {

    return (
        <main>

            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/kariyer" />
                <title>Kariyer - Time Yazılım</title>
                <meta name="description" content="Time Yazılım olarak, yetenekli ve motive bir ekibe katılmak isteyenleri arıyoruz. Bizimle çalışarak kariyerinizi geliştirebilir, yeni beceriler öğrenebilir ve heyecan verici projelerde yer alabilirsiniz. Açık pozisyonlarımızı inceleyin ve başvurun!" />
            </Helmet>


            <HalfBanner page={"KARİYER"} />

            <section className="pt-20">
                <article className="container">
                    <div className="mx-auto text-center lg:w-10/12">
                        <h2 className="text-3xl italic font-bold">Time Yazılımda  Çalışma Pozisyonlarına Başvurun</h2>
                        <p className="mt-6 mb-12 text-sm font-medium opacity-75">Time Yazılım, yenilikçi ve dinamik bir iş ortamında kariyerinizi geliştirebileceğiniz birçok fırsat sunmaktadır. Sürekli olarak büyüyen bir ekibin parçası olmak ve dijital dünyanın geleceğini birlikte şekillendirmek için hemen başvurun! heyecan verici projelerde yer almak istiyorsanız, bize katılın ve birlikte başarıya ulaşalım!</p>
                    </div>
                </article>
                <article className="container flex flex-col gap-9">
                    <KariyerCardList />
                </article>
            </section>

            <section>
                <article className="container py-20">
                    <div className="flex flex-col md:flex-row gap-9">
                        <div className="basis-7/12">
                            <form action="" >
                                <span className="text-3xl italic font-bold">Kariyer Basamakları</span>
                                <div className="p-9 shadow-[_0_0_2px_rgb(0_0_0_/_25%)] mt-6 rounded-md">
                                    <div className="flex flex-col items-center justify-between gap-6 md:flex-row">
                                        <div className="w-full">
                                            <label className="text-lg font-bold" htmlFor="name">Adınız ve Soyadınız</label>
                                            <div className="relative mt-3">
                                                <input autoComplete="name" className="w-full py-3 bg-[#EEE] rounded-md px-12 focus:outline focus:outline-[1px] focus:outline-zinc-800" required type="text" name="name" id="name" placeholder="Adınız ve Soyadınız" />
                                                <div className="absolute flex items-center opacity-50 bottom-1 top-1 left-3">
                                                    <ChangeSVG name={'user'} width={20} height={20} color={'#000'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <label className="text-lg font-bold" htmlFor="phone">Telefon Numaranız</label>
                                            <div className="relative mt-3">
                                                <input autoComplete="phone" className="w-full py-3 bg-[#EEE] rounded-md px-12 focus:outline focus:outline-[1px] focus:outline-zinc-800" required type="text" name="phone" id="phone" placeholder="Telefon numaranızı giriniz" />
                                                <div className="absolute flex items-center opacity-50 bottom-1 top-1 left-3">
                                                    <ChangeSVG name={'phone'} width={20} height={20} color={'#000'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-6">
                                        <label className="text-lg font-bold" htmlFor="mail">Mail Adresiniz</label>
                                        <div className="relative mt-3">
                                            <input autoComplete="mail" className="w-full py-3 bg-[#EEE] rounded-md px-12 focus:outline focus:outline-[1px] focus:outline-zinc-800" required type="email" name="mail" id="mail" placeholder="Mail adresinizi giriniz" />
                                            <div className="absolute flex items-center opacity-50 bottom-1 top-1 left-3">
                                                <ChangeSVG name={'envelope'} width={20} height={20} color={'#000'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-6">
                                        <label className="text-lg font-bold" htmlFor="subject">Bahsedin</label>
                                        <div className="relative mt-3">
                                            <textarea autoComplete="subject" rows={5} className="w-full py-3 bg-[#EEE] rounded-md resize-none px-4 focus:outline focus:outline-[1px] focus:outline-zinc-800" required type="text" name="subject" id="subject" placeholder="Sizi tanımamız için biraz kendinizden bahsedin." />
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-6">
                                        <input className="bg-[#21B3F5] hover:bg-[#54c9ff] duration-300 py-2 px-8 rounded-full text-white" type="submit" value="Gönder" />
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="basis-5/12">
                            <ChangeIMG page={'kariyer'} name={'kariyercontact'} alt={'Time Kariyer'} rounded={'md'} />
                        </div>
                    </div>
                </article>
            </section>

        </main>
    )
}

export default Kariyer;