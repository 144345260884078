import ChangeSVG from '../helpers/ChangeSVG';

const StandSlide = ({ title, description, svg }) => {

    return (
        <div className="flex items-center gap-3 p-2 bg-white rounded-md standCard bg-opacity-10">
            <ChangeSVG name={svg} width={60} height={60} color="#fff" />
            <div className="flex flex-col gap-1">
                <span className="text-base font-bold">{title}</span>
                <span className="text-xs opacity-50">{description}</span>
            </div>
        </div>
    )
}

export default StandSlide;