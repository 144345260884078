import React, { useState } from 'react';
import Accordion from './Accordion';

function AccordionContainer({ faq }) {
    const [openAccordion, setOpenAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <article className='container flex flex-col gap-6'>
            <h3 className='mb-5 text-3xl italic font-bold text-center'>Sıkça Merak Edilen Konular</h3>
            {faq.map((item, index) => (
                <Accordion
                    key={index}
                    title={item.title}
                    content={item.content}
                    isOpen={openAccordion === index}
                    onClick={() => handleAccordionClick(index)}
                />
            ))}
        </article>
    );
}

export default AccordionContainer;
