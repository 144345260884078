import { Helmet } from "react-helmet";
import HalfBanner from "../components/HalfBanner";
import MisyonCardList from '../components/MisyonCard';
import ChangeIMG from "../helpers/ChangeIMG";

const Misyon = () => {

    return (
        <main>
            <HalfBanner page={"MİSYON & VİZYON"} />

            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/misyon" />
                <title>Misyon & Vizyon - Time Yazılım</title>
                <meta name="description" content="Time Yazılım olarak, misyonumuz ve vizyonumuz doğrultusunda teknolojiyi kullanarak müşterilerimize en iyi çözümleri sunmak ve sürekli olarak yenilikçi olmaktır. Misyonumuz, müşteri odaklı yaklaşımımız ve teknolojiyi kullanarak sürdürülebilir çözümler sunmaktır. Vizyonumuz ise, sektörde lider bir teknoloji şirketi olmak ve müşterilerimize değer katmaktır." />
            </Helmet>

            <section className="pt-20">
                <article className="container">
                    <h2 className="text-3xl italic font-extrabold">Time Misyonu</h2>
                    <div className="grid grid-cols-1 mt-5 lg:grid-cols-2 gap-9">
                        <MisyonCardList />
                    </div>
                </article>
            </section>

            <section className="py-20">
                <article className="container">
                    <div className="flex flex-col items-center md:flex-row gap-9">
                        <div className="basis-1/2">
                            <ChangeIMG page={'misyon'} name={'vizyonimage'} alt={'Vizyonumuz'} rounded={'md'} />
                        </div>
                        <div className="basis-1/2">
                            <h3 className="text-2xl text-[#FFC107] font-extrabold italic tracking-widest">BİZİ ANLAMLANDIRAN VİZYONUMUZ</h3>
                            <p className="mt-6 text-sm mb-9">Dijital dünyada öncü bir rol üstlenmek ve teknolojiyi kullanarak insan yaşamını daha iyi hale getirmek için ileriye dönük bir vizyonla hareket ediyoruz. Sürekli olarak kendimizi geliştirerek, sektördeki en son trendleri takip ediyor ve müşterilerimize en iyi hizmeti sunmak için çalışıyoruz. Geleceğe dair hayallerimizi gerçekleştirmek için tutkuyla çalışıyoruz</p>
                            <div className="flex items-center mb-4 gap-9 ps-4">
                                <div className="border-l-2 border-[#FFC107] h-[5rem]"></div>
                                <div>
                                    <span className="text-lg font-extrabold">Vizyonumuz</span>
                                    <p className="mt-3 text-sm opacity-50">Dijital dönüşüm için sürekli olarak yenilikçi çözümler geliştirerek gerek tasarım alanında gerek yazılım alanında teknoloji şirketi olmayı vizyon ediniyoruz.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
        </main>
    )
}

export default Misyon;