
import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";

const businessData = [
    {
        title: 'Palet Merkezi Tasarım & Yazarım',
        items: ['B2B Projesi', 'Web Tasarım', 'E-Ticaret'],
        description: 'Bilginoğulları Palet için modern ve kullanıcı dostu bir web sitesi oluşturduk. Müşterilerin ürünleri keşfetmesini ve hizmetler hakkında bilgi almasını kolaylaştırmak için kullanıcı odaklı bir tasarım yaklaşımı benimsedik.',
        image: 'referanspalet',
        url: 'https://paletmerkezi.com/'
    },
    {
        title: 'Bilginoğulları Palet Tasarım & Yazılım',
        items: ['Web Tasarımı', 'Web Yazılımı', 'Panel Yönetimi'],
        description: 'Müşterimizin ihtiyaçlarına uygun modern bir tasarım oluşturarak, kullanıcıların siteyi kolayca gezmesini ve ürünleri keşfetmesini sağladık. Aynı zamanda, kaliteyi yansıtan bir kimlik oluşturarak marka imajını güçlendirdik.',
        image: 'referansbilgin',
        url: 'https://bilginogullari.com/'
    },
    {
        title: 'Feksan Makine Tasarım & Yazılım',
        items: ['Web Yazılımı', 'Web Tasarımı', 'Responsive Yazılım'],
        description: 'Endüstriyel makinelerin üretimi ve satışı konusunda uzmanlaşmış olan Feksan Makine için, modern ve işlevsel bir web sitesi oluşturduk. Web sitesi, ürün yelpazesini etkili bir şekilde sergiler ve detaylı bir bilgi sunar.',
        image: 'referansfeksan',
        url: 'https://feksanmakina.com/'
    }
];

const BusinessCard = ({ data }) => {

    const { title, items, description, image, url } = data;

    return (
        <div className="overflow-hidden bg-white businessCard px-9">
            <div className="relative flex flex-col items-center justify-between md:flex-row">
                <div className="order-1 lg:basis-6/12">
                    <span className="text-2xl italic font-extrabold">{title}</span>
                    <div className="flex flex-col items-start gap-6 my-6 md:items-center md:flex-row">
                        {
                            items.map((item, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <ChangeSVG name="dotyellow" width={24} height={24} color="" />
                                    <span className="text-sm opacity-75">{item}</span>
                                </div>
                            ))
                        }
                    </div>
                    <p className="text-sm opacity-50">{description}</p>
                    <a className="bg-[#FFC107] py-2 px-6 inline-flex mt-6 mb-6 md:mb-0 text-xs font-semibold rounded-md" href={`${url}`} target="_blank" rel="noopener noreferrer" >PROJEYİ GÖRÜNTÜLE</a>
                </div>
                <div className="z-10 basis-5/12">
                    <ChangeIMG page={'anasayfa'} name={image} alt={title} />
                </div>
                <div className="absolute top-0 bottom-0 right-0 hidden md:flex">
                    <ChangeSVG name="timeobj" width={''} height={''} color="" />
                </div>
            </div>
        </div>
    );
}

const BusinessCardList = () => {
    return (
        <div className="flex flex-col gap-9">
            {
                businessData.map((data, index) => (
                    <BusinessCard key={index} data={data} />
                ))
            }
        </div>
    );
}

export default BusinessCardList;