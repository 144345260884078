
import './assets/css/tailwindconfig.css'
import './assets/css/main.css'
import './assets/css/responsive.css'

// React Library

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Layouts

import UserLayout from './layouts/UserLayout';

// Pages

import ErrorPage from './pages/ErrorPage';

import Home from './pages/Home';
import Hakkimizda from './pages/Hakkimizda';
import Kariyer from './pages/Kariyer';
import Misyon from './pages/Misyon';
import Hizmetlerimiz from './pages/Hizmetlerimiz';
import HizmetDetay from './pages/HizmetDetay';
import Projelerimiz from './pages/Projelerimiz';
import Blog from './pages/Blog';
import BlogDetay from './pages/BlogDetay';
import Iletisim from './pages/Iletisim';


const App = () => {
  return (
    <Routes>

      <Route path='/' element={<UserLayout><Home /></UserLayout>}></Route>
      <Route path='/sayfa-bulunamadi' element={<UserLayout><ErrorPage /></UserLayout>}></Route>
      <Route path='*' element={<Navigate to="/sayfa-bulunamadi" />}></Route>

      <Route path='/hakkimizda' element={<UserLayout><Hakkimizda /></UserLayout>}></Route>
      <Route path='/kariyer' element={<UserLayout><Kariyer /></UserLayout>}></Route>
      <Route path='/misyon' element={<UserLayout><Misyon /></UserLayout>}></Route>

      <Route path='/hizmetlerimiz' element={<UserLayout><Hizmetlerimiz /></UserLayout>}></Route>
      <Route path='/hizmetlerimiz/:id' element={<UserLayout><HizmetDetay /></UserLayout>}></Route>

      <Route path='/projelerimiz' element={<UserLayout><Projelerimiz /></UserLayout>}></Route>
      <Route path='/blog' element={<UserLayout><Blog /></UserLayout>}></Route>
      <Route path='/blog/:id' element={<UserLayout><BlogDetay /></UserLayout>}></Route>

      <Route path='/iletisim' element={<UserLayout><Iletisim /></UserLayout>}></Route>


    </Routes>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default App;
