import { Link, useParams } from "react-router-dom";
import BlogBackground from "../assets/images/blog/blogbackground.png";
import ChangeSVG from "../helpers/ChangeSVG";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import Api from "../api/Api";

const BlogDetay = () => {

    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const blogData = await Api.get_blog_id(id);
                setBlog(blogData);
            } catch (error) {
                console.error('Hata:', error);
            }
        };

        fetchData();
    }, [id]);


    // const blog = blogs.find((item) => item.id === id);

    const data = [
        { 'svg': 'specialdesign', 'title': 'Kurumsal Web Tasarımı' }, { 'svg': 'specialsupport', 'title': 'Özel Web Yazılımı' },
        { 'svg': 'phonedesign', 'title': 'Mobile Uygulama Tasarımı' }, { 'svg': 'socialmedia', 'title': 'Sosyal Medya Tasarımı' },
        { 'svg': 'softwarephone', 'title': 'Mobile Uygulama Yazılımı' }, { 'svg': 'seo', 'title': 'SEO Optimizasyon Hizmeti' },
    ];

    if (!blog) {
        return <div>Böyle bir hizmet bulunamadı.</div>;
    }

    return (
        <main>

            <Helmet>
                <link rel="canonical" href={`https://timeyazilim.com.tr/${id}`} />
                <title>{blog.blog_title}</title>
                <meta name="description" content={`${blog.blog_description}`} />
            </Helmet>

            <div className="relative">
                <article className="container pt-12 text-white lg:pt-28">
                    <div className="flex flex-col md:flex-row gap-9">
                        <div className="lg:basis-9/12">
                            <div className="px-5 categoryCard bg-[#21A0D9] bg-opacity-15 backdrop-blur-xl py-6 rounded-md border border-blue-500 border-opacity-25">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-3">
                                        <ChangeSVG name={'dotyellow'} width={24} height={24} />
                                        <span className="text-[#FCC009] text-sm mt-[2px]">{blog.blog_category}</span>
                                    </div>
                                    <span className="text-sm opacity-50">{blog.blog_date}</span>
                                </div>
                                <h1 className="mt-6 text-3xl italic font-bold">{blog.blog_title}</h1>
                                <div className="py-5">
                                    {/* <ChangeIMG page={'blog'} name={blog.image} rounded={'md'} /> */}
                                    <img src={`http://localhost:8888/timeyazilim/api/asset/uploads/blogs/${blog.blog_photo}`} alt="Blog Görseli" />
                                </div>
                                <p className="mt-4 text-sm opacity-50">{blog.blog_description}</p>
                                {
                                    // blog.long_contnet.map((content, index) => (
                                    //     <div key={index} className="flex flex-col gap-6 mt-6">
                                    //         <div className="text-2xl italic font-bold">{content.title}</div>
                                    //         <div className="text-sm opacity-50">{content.content}</div>
                                    //     </div>
                                    // ))
                                }
                                <div
                                    className="flex flex-col mt-6 paragraph-container"
                                    dangerouslySetInnerHTML={{ __html: blog.blog_content }}
                                ></div>
                            </div>
                        </div>
                        <div className="lg:basis-3/12">
                            <div className="flex flex-col gap-4 categoryCard bg-[#21A0D9] bg-opacity-15 backdrop-blur-xl py-6 px-3 rounded-md border border-blue-500 border-opacity-25">
                                <div className="flex items-center gap-4 p-3 categoryItem bg-[#00AEEF] rounded-md bg-opacity-10 ">
                                    <ChangeSVG name={'category_menu'} height={24} width={24} color='#18E3FF' />
                                    <span className="text-sm">Tüm Referanslar</span>
                                </div>
                                {
                                    data.map((card, index) => (
                                        <Link to={'/blog'} key={index} className="flex items-center gap-4 p-3 bg-white rounded-md opacity-50 categoryItem bg-opacity-10 hover:bg-[#00AEEF] duration-300 hover:bg-opacity-15 hover:opacity-100 hover:text-[#18E3FF] group">
                                            <div className="group-hover:fill-[#18E3FF] fill-white">
                                                <ChangeSVG name={card.svg} height={24} width={24} />
                                            </div>
                                            <span className="text-sm">{card.title}</span>
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </article>

                <section className="fixed top-0 bottom-0 end-0 start-0 -z-10">
                    <img className="object-cover w-full h-full" src={BlogBackground} alt="" />

                </section>
            </div>
            <section className="py-10"></section>
        </main>
    )
}

export default BlogDetay;