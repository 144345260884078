
const ChangeIMG = ({ page, name, alt, rounded = null, height = null }) => {

    const pathImage = require(`../assets/images/${page}/${name}.png`);

    if (!pathImage) {
        return <div>Hata!</div>;
    }

    return (
        <img className={`${height === 'banner' ? 'h-[400px] lg:h-full' : height === 'footer' ? 'h-[1450px] lg:h-full' : height === 'hizmetdetay' ? 'h-[1450px] lg:h-full' : height === 'section' ? 'h-[875px] lg:h-full' : ''} object-cover w-full rounded-${rounded}`} src={pathImage} alt={alt} title={alt} />
    );

}

export default ChangeIMG;