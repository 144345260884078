import axios from "axios";

const API_BASE_URL = "https://api.timeyazilim.com.tr";
// const API_BASE_URL = "http://localhost:8888/timeyazilim/api";

const Api = axios.create({
    baseURL: `${API_BASE_URL}`,
    // withCredentials: true
});

const handleRequest = async (method, url, data = {}) => {
    try {
        const response = await method(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

Api.get_blogs = async () => {
    return handleRequest(Api.post, "/blog/",);
};

Api.get_blog_id = async (id) => {
    return handleRequest(Api.post, `/blog/list/${id}`,);
};

export default Api;