
import ChangeIMG from '../helpers/ChangeIMG';
import { Link } from 'react-router-dom';
import ChangeSVG from '../helpers/ChangeSVG';

const UserFooter = () => {

    return (
        <footer>
            <div className="relative text-white footer">
                <ChangeIMG page={'anasayfa'} name={'footer'} height={'footer'} />
                <div className="absolute top-0 bottom-0 start-0 end-0">
                    <div className="container pt-20">
                        <div className='flex flex-col justify-between lg:flex-row'>
                            <div className='flex flex-col justify-between gap-9 lg:flex-row basis-6/12 lg:gap-0'>
                                <div className="lg:basis-4/12">
                                    <span className='text-lg font-bold'>Bağlantılarımız</span>
                                    <ul className='flex flex-col gap-4 text-sm mt-9'>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/'>Anasayfa</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/projelerimiz'>Projelerimiz</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz'>Hizmetlerimiz</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/blog'>Bloglar</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/iletisim'>İletişim</Link></li>
                                    </ul>
                                </div>
                                <div className="lg:basis-4/12">
                                    <span className='text-lg font-bold'>Hizmetlerimiz</span>
                                    <ul className='flex flex-col gap-4 text-sm mt-9'>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz/web-yazilim'>Kurumsal Web Tasarımı</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz/web-tasarim'>Özel Web Yazılımı</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz/seo'>Seo Optimizasyonu</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz/mobil-uygulama-tasarimi'>Mobile UI Tasarımı</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz/mobil-uygulama-yazilimi'>Mobile Uygulama</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hizmetlerimiz/sosyal-medya-tasarimi'>Sosyal Medya Tasarımı</Link></li>
                                    </ul>
                                </div>
                                <div className="lg:basis-4/12">
                                    <span className='text-lg font-bold'>Kurumsal</span>
                                    <ul className='flex flex-col gap-4 text-sm mt-9'>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/hakkimizda'>Hakkımızda</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/kariyer'>Time’da Kariyer</Link></li>
                                        <li className='duration-300 opacity-75 hover:opacity-100'><Link to='/misyon'>Misyon & Vizyon</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="lg:basis-5/12 mt-9 lg:mt-0">
                                <span className='text-lg font-bold'>Bizi Takip Edin</span>
                                <div className='mt-6'>
                                    <ul className="flex items-center gap-4 socialMedia">
                                        <li><Link to='https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m' target='_blank'><ChangeSVG name="colorfullinkedin" width={34} height={34} color="" /></Link></li>
                                        <li><Link to='https://www.instagram.com/timeyazilimm?igsh=MXJ4dzdhdDFpbW51bg%3D%3D&utm_source=qr' target='_blank'><ChangeSVG name="colorfulinstagram" width={34} height={34} color="" /></Link></li>
                                        {/* <li><Link to='https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m' target='_blank'><ChangeSVG name="colorfultwitter" width={34} height={34} color="#fff" /></Link></li> */}
                                        {/* <li><Link to='https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m' target='_blank'><ChangeSVG name="colorfulfacebook" width={34} height={34} color="#fff" /></Link></li> */}
                                    </ul>
                                    <span className='inline-flex py-6 text-sm opacity-75'>Muallimköy Mahallesi, Deniz Cd. No:143-5, 41400 Gebze/Kocaeli</span>
                                    <div className='flex flex-col items-start gap-3 text-sm lg:items-center lg:flex-row'>
                                        <div className='flex items-center gap-2 text-sm'>
                                            <ChangeSVG name="location" width={16} height={16} color="#fff" />
                                            <span>Bilişim Vadisi Gebze / Kocaeli</span>
                                        </div>
                                        <div className='flex items-center gap-2 text-sm cursor-pointer' onClick={(e) => { window.location.href = 'mailto:info@timeyazilim.com.tr'; }} >
                                            <ChangeSVG name="envelope" width={16} height={16} color="#fff" />
                                            <span>info@timeyazilim.com.tr</span>
                                        </div>
                                    </div>
                                    <span className='inline-flex py-6 text-sm opacity-75'>Profesyonel ekibimiz ve çözüm ortakları ile 360° hizmet sunarak müşterilerine sürdürülebilir ve hızlı hizmet vermek için çalışıyoruz.</span>
                                    <Link to='https://wa.me/905418434313' target="_blank" className='flex items-center gap-2 text-sm'>
                                        <ChangeSVG name="phone" width={16} height={16} color="#fff" />
                                        <div className='flex items-center gap-2'><span>+90 0541 843 43 13 </span> <span className='opacity-25'>Bize Ulaşın!</span></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='mt-20 border border-white border-opacity-25' />
                        <div className="flex flex-col items-start justify-between gap-3 mt-8 lg:items-center lg:flex-row lg:gap-0">
                            <ChangeSVG name="logo" width={140} height={64} color="#fff" />
                            <span className='text-sm opacity-50'>© Copyright 2022 Time Yazılım Teknoloji A.Ş Tüm Hakları Saklıdır.</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default UserFooter;