
import { Link } from "react-router-dom";
import ChangeSVG from "../helpers/ChangeSVG";
import ChangeIMG from '../helpers/ChangeIMG';

const BannerSlide = ({ items, title, paragraph, svg, image, alt }) => {

    return (
        <>
            <div className="flex flex-col items-center justify-center w-full h-full md:flex-row gap-9">
                <div className="basis-6/12">
                    <div className="relative w-full h-full overflow-hidden rounded-md">
                        <ChangeIMG page='anasayfa' name={image} alt={alt} title={alt} />
                        <div className="absolute bottom-0 start-0 end-0 bg-[#21A0D9] py-2 px-4 flex items-center justify-between">
                            <span className="text-xs font-semibold opacity-50">{title}</span>
                            <span className="italic font-bold">Zamanın Ötesine Taşıyoruz!</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-12 basis-6/12">
                    <div className="flex-col items-start hidden gap-6 md:items-center md:flex-row md:flex">
                        {
                            items.map((item, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <ChangeSVG name="dot" width={24} height={24} color="" />
                                    <span className="text-sm opacity-75">{item}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className="flex flex-col items-start gap-6 md:items-center md:flex-row">
                        <div className="w-16 h-16 bg-[#FFF] bg-opacity-10 border border-[#00B1FF] items-center justify-center rounded-md hidden md:flex">
                            <ChangeSVG name={svg} width={36} height={36} color="#fff" />
                        </div>
                        <div>
                            <span className="text-sm text-[#00B1FF] font-semibold italic tracking-widest">DİJİTAL DÖNÜŞÜMÜNÜZ ZAMANIN ÖTESİNDE!</span>
                            <h2 className="mt-2 text-3xl font-rubik">{title}</h2>
                        </div>
                    </div>
                    <p className="text-sm opacity-75">{paragraph}</p>
                    <div className="items-center hidden gap-5 text-sm font-bold md:flex">
                        <Link to="/iletisim" className="bg-[#21A0D9] hover:bg-[#51c8ff] duration-500 hover:shadow-[_0_0_25px_rgb(33_160_217_/_100%)] shadow-[_0_0_15px_rgb(33_160_217_/_100%)] py-3 px-12 rounded-md">TEKLİF AL</Link>
                        <Link to="/projelerimiz" className="px-12 py-3 duration-300 bg-transparent border border-white rounded-md hover:text-[#21A0D9] hover:bg-white">PROJELERİMİZ</Link>
                    </div>
                    <span className="hidden text-sm font-bold md:block">Her Sektörün Özel İhtiyaçlarına Çözüm Üretiyoruz!</span>
                </div>
            </div>
        </>
    )
}

export default BannerSlide;