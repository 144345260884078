import { Helmet } from "react-helmet";
import HalfBanner from "../components/HalfBanner";
import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";
import { Link } from 'react-router-dom';

const Iletisim = () => {
    return (
        <main>
            <HalfBanner page={"BİZİME İLETİŞİM KURUN"} />

            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/iletisim" />
                <title>İletişim - Time Yazılım</title>
                <meta name="description" content="Time Yazılım ile iletişime geçmek için bu sayfayı kullanabilirsiniz. Sorularınızı, önerilerinizi veya işbirliği tekliflerinizi bize iletmek için iletişim bilgilerimizi kullanabilir veya iletişim formumuzu doldurabilirsiniz. Size en kısa sürede yanıt vermeye hazırız!" />
            </Helmet>

            <section className="py-20">
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
                        <Link to='https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m' className="flex items-center gap-3 p-4 bg-white rounded-md socialCard">
                            <ChangeSVG name={'colorfullinkedin'} width={56} height={56} />
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl italic font-bold">Linkedin</span>
                                <span className="text-sm">company/time-yazilim</span>
                            </div>
                        </Link>
                        <Link to='https://www.instagram.com/timeyazilimm?igsh=MXJ4dzdhdDFpbW51bg%3D%3D&utm_source=qr' className="flex items-center gap-3 p-4 bg-white rounded-md socialCard">
                            <ChangeSVG name={'colorfulinstagram'} width={56} height={56} />
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl italic font-bold">Instagram</span>
                                <span className="text-sm">company/time-yazilim</span>
                            </div>
                        </Link>
                        <div onClick={(e) => { window.location.href = 'mailto:info@timeyazilim.com.tr'; }} className="flex items-center w-full gap-4 p-4 bg-white rounded-md cursor-pointer md:w-auto socialCard">
                            <ChangeSVG name={'envelope'} width={45} height={45} color='#FFC107' />
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl italic font-bold">Bize Mail Atın</span>
                                <span className="text-sm">info@timeyazilim.com.tr</span>
                            </div>
                        </div>
                        {/* <div className="flex items-center gap-3 p-4 bg-white rounded-md socialCard">
                            <ChangeSVG name={'twitter'} width={56} height={56} />
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl italic font-bold">Twitter ‘x’</span>
                                <span className="text-sm">company/time-yazilim</span>
                            </div>
                        </div> */}
                        {/* <div className="flex items-center gap-3 p-4 bg-white rounded-md socialCard">
                            <ChangeSVG name={'colorfulfacebook'} width={56} height={56} />
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl italic font-bold">Faceboook</span>
                                <span className="text-sm">company/time-yazilim</span>
                            </div>
                        </div> */}
                    </div>
                    <div className="flex flex-col items-center justify-center mt-12 md:flex-row gap-9">
                        <Link to='https://wa.me/905418434313' className="flex items-center w-full gap-4 p-4 px-12 bg-white rounded-md md:w-auto socialCard" target="_blank">
                            <ChangeSVG name={'phone'} width={42} height={42} color='#FFC107' />
                            <div className="flex flex-col gap-1 cursor-pointer">
                                <span className="text-2xl italic font-bold">Bizi Arayın</span>
                                <span className="text-sm">+90 0541 843 43 13</span>
                            </div>
                        </Link>
                        <Link target="_blank" to='https://www.google.com/maps/place/Time+Yaz%C4%B1l%C4%B1m/@40.7882186,29.5102992,15z/data=!4m2!3m1!1s0x0:0x6c5edb84bcff4858?sa=X&ved=1t:2428&hl=tr&ictx=111' className="flex items-center w-full gap-4 p-4 px-12 bg-white rounded-md md:w-auto socialCard">
                            <ChangeSVG name={'location'} width={42} height={42} color='#FFC107' />
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl italic font-bold">Konumumuz</span>
                                <span className="text-sm">Bilişim Vadisi Gebze / Kocaeli</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="pt-10">
                <article className="container text-center">
                    <h2 className="text-3xl font-rubik">BİZİMLE İLETİŞİME GEÇİN</h2>
                    <p className="flex items-center mx-auto mt-6 lg:w-8/12">Time Yazılım olarak, müşterilerimize çağın gereksinimlerine uygun kapsamlı hizmetler sunuyoruz. Web, mobil, sosyal medya ve daha fazlasıyla ilgili geniş bir yelpazede hizmetlerimiz bulunmaktadır</p>
                </article>
                <article className="container pt-20">
                    <div className="flex flex-col md:flex-row gap-7">
                        <div className="basis-4/12">
                            <ChangeIMG page={'anasayfa'} name={'iletisimphoto'} alt={'Time İletişim'} rounded={'md'} />
                        </div>
                        <div className="basis-8/12">
                            <form className="h-full px-6 bg-white py-9" action="">
                                <div className="text-2xl font-extrabold">İletişim Formu</div>
                                <p className="mt-6 italic opacity-50">Time Yazılım olarak geçmişten bugüne pek çok projede başarılı işlere imza attık ve müşterilerimize değerli hizmetler sunmaktan gurur duyuyoruz.</p>
                                <div className="">
                                    <div className="flex flex-col items-center gap-0 md:gap-5 md:flex-row">
                                        <div className="flex flex-col w-full gap-2 mt-4 text-sm">
                                            <label htmlFor="name" className="font-bold">Adınızı ve Soyadınız</label>
                                            <div className="relative">
                                                <input autoComplete="name" className="w-full p-2 border rounded-md" type="text" name="name" id="name" placeholder="Adınızı ve soyadınızı giriniz" />
                                                <div className="absolute flex items-center justify-center opacity-25 bottom-1 top-2 right-3">
                                                    <ChangeSVG name={'user'} width={16} height={16} color='#000' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-2 mt-4 text-sm">
                                            <label htmlFor="phone" className="font-bold">Telefon Numarası</label>
                                            <div className="relative">
                                                <input autoComplete="phone" className="w-full p-2 border rounded-md" type="text" name="phone" id="phone" placeholder="Adınızı ve soyadınızı giriniz" />
                                                <div className="absolute flex items-center justify-center opacity-25 bottom-1 top-1 right-3">
                                                    <ChangeSVG name={'phone'} width={16} height={16} color='#000' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center gap-0 md:gap-5 md:flex-row">
                                        <div className="flex flex-col w-full gap-2 mt-4 text-sm lg:w-1/2">
                                            <label htmlFor="mail" className="font-bold">Konu</label>
                                            <div className="relative">
                                                <textarea autoComplete="subject" className="w-full p-2 border rounded-md resize-none" type="text" name="subject" id="subject" rows={4} placeholder="Adınızı ve soyadınızı giriniz" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-4 lg:w-1/2">
                                            <div className="flex flex-col w-full gap-2 mt-4 text-sm">
                                                <label htmlFor="mail" className="font-bold">Mail Adresi</label>
                                                <div className="relative">
                                                    <input autoComplete="mail" className="w-full p-2 border rounded-md" type="text" name="mail" id="mail" placeholder="Adınızı ve soyadınızı giriniz" />
                                                    <div className="absolute flex items-center justify-center opacity-25 bottom-1 top-1 right-3">
                                                        <ChangeSVG name={'envelope'} width={16} height={16} color='#000' />
                                                    </div>
                                                </div>
                                            </div>
                                            <input className="bg-[#FFC107] py-2 px-4 rounded-md w-full font-bold text-sm" type="submit" value="Formu Gönder" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </article>
            </section>

            <section className="container py-10 md:py-16">
                <iframe title="Google Haritası" className="w-full h-[25rem]"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12083.234984009143!2d29.5102992!3d40.7882186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb216a0d903ec5%3A0x6c5edb84bcff4858!2zVGltZSBZYXrEsWzEsW0!5e0!3m2!1str!2str!4v1710251700070!5m2!1str!2str"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </section>


        </main>
    )
}

export default Iletisim;