import ChangeSVG from "../helpers/ChangeSVG";
import ChangeIMG from '../helpers/ChangeIMG';
import { Link } from "react-router-dom";

const serviceData = [

    {
        svg: 'specialsupport',
        svgColor: '#fff',
        title: 'Özel Web Yazılımı',
        description: 'İşletmelerin özel iş süreçlerine uygun olarak özelleştirilmiş web tabanlı uygulamalar geliştiriyoruz. Web uygulamalarıyla işletmelerin dijital dünyada ki varlığını ve verimliliğini arttırmayı hedefleyen bir hizmet anlayışımız bulunmaktadır.',
        image: 'webyazilim',
        imageColor: 'bg-green-900',
        imageDescription: 'İşletmelerin özel iş süreçlerine uygun olarak özelleştirilmiş web tabanlı uygulamalar geliştiriyoruz.',
        service_id: 'web-yazilim'
    },
    {
        svg: 'specialdesign',
        svgColor: '#000',
        title: 'Kurumsal Web Tasarımı',
        description: 'Kurumsal Web Tasarımı hizmetimiz, işletmelerin dijital varlıklarını güçlendirmelerine yardımcı  olmaktadır. Özel ihtiyaçlara uygun çözümler sunarak, kurumsal kimliklerini dijital platformda güçlendirmelerine yardımcı oluyoruz.',
        image: 'webtasarim',
        imageColor: 'bg-black',
        imageDescription: 'İşletmelerin dijital varlıklarını güçlendirmelerine ve çevrimiçi varlıklarını etkili bir şekilde yönetmelerine yardımcı olmak için buradayız.',
        service_id: 'web-tasarim'
    },
    {
        svg: 'seo',
        svgColor: '#000',
        title: 'SEO Optimizasyon Hizmeti',
        description: 'SEO Optimizasyonu hizmetimiz, web sitelerinin arama motorlarında daha üst sıralarda yer almasını sağlamak için tasarlanmış bir strateji sunar. Trafiklerini artırmalarını güçlendirmelerini sağlayacak SEO hizmeti sunarız.',
        image: 'seoopt',
        imageColor: 'bg-orange-900',
        imageDescription: 'Web sitelerinin arama motorlarında daha üst sıralarda yer almasını sağlamak için tasarlanmış kapsamlı bir strateji sunmaktayız.',
        service_id: 'seo'
    },
    {
        svg: 'phonedesign',
        svgColor: '#fff',
        title: 'Mobile Uygulama Tasarımı',
        description: 'Kullanıcı deneyimini ön planda tutarak modern, işlevsel ve çekici mobil uygulamalar geliştirmek için uzman bir ekip tarafından yönetilir.  müşterilerimizin uygulamalarında en iyi kullanıcı deneyimini sağlamak için titizlikle yönetilir.',
        image: 'mobiltasarim',
        imageColor: 'bg-rose-900',
        imageDescription: 'Modern zamanın trend tasarımlarını ele alırken aynı zamanda nostaljinin gücünüde kullanıyor zamanın ötesinde tasarımlar üretiyoruz!',
        service_id: 'mobil-uygulama-tasarimi'
    },
    {
        svg: 'softwarephone',
        svgColor: '#000',
        title: 'Mobile Uygulama Yazılımı',
        description: 'Müşterilerimizin iş gereksinimlerini karşılamak için özelleştirilmiş, ölçeklenebilir ve güvenli mobil uygulamalar geliştirmek için uzman bir ekip tarafından yönetilir. Fikirleri gerçeğe dönüştürmek için kapsamlı bir yazılım geliştirme sürecini içerir.',
        image: 'mobilyazilim',
        imageColor: 'bg-purple-900',
        imageDescription: 'Kullanıcı deneyimini ön planda tutarak modern, işlevsel ve çekici mobil uygulamalar geliştirmek için uzman ekibimiz ile desteklemekteyiz.',
        service_id: 'mobil-uygulama-yazilimi'
    },
    {
        svg: 'socialmedia',
        svgColor: '#fff',
        title: 'Sosyal Medya Post Tasarımı',
        description: 'SEO Optimizasyonu hizmetimiz, web sitelerinin arama motorlarında daha üst sıralarda yer almasını sağlamak için tasarlanmış bir strateji sunar. Trafiklerini artırmalarını güçlendirmelerini sağlayacak SEO hizmeti sunarız.',
        image: 'sosyalmedyatasarim',
        imageColor: 'bg-blue-900',
        imageDescription: 'Modern zamanın trend tasarımlarını ele alırken aynı zamanda nostaljinin gücünüde kullanıyor zamanın ötesinde tasarımlar üretiyoruz!',
        service_id: 'sosyal-medya-tasarimi'
    }
];

const ServiceCard = ({ data }) => {

    const { svg, svgColor, title, description, image, imageColor, imageDescription, service_id } = data;

    return (
        <div className="relative group perspective-800">
            <div className="backface-hidden transform transition duration-[.75s] group-hover:rotate-y-180">
                <div className="serviceCard shadow-[_0_0_2px_rgb(0_0_0_/25%)] px-6 py-12 text-center">
                    <div className="relative inline-flex">
                        <ChangeSVG name='joke' width={124} height={124} color="#FCC009" />
                        <div className="absolute top-0 bottom-0 flex items-center justify-center start-0 end-0">
                            <ChangeSVG name={svg} width={80} height={80} color={svgColor} />
                        </div>
                    </div>
                    <h4 className="text-2xl font-bold mt-9">{title}</h4>
                    <p className="mt-5 text-sm text-justify ">{description}</p>
                </div>
            </div>
            <div className="absolute top-0 bottom-0 end-0 start-0">
                <div className="backface-hidden transform transition duration-[.75s] -rotate-y-180 group-hover:rotate-y-0 h-full">
                    <div className="relative">
                        <ChangeIMG page={'anasayfa'} name={image} alt={'Web Geliştirme'} />
                        <div className={`absolute top-0 bottom-0 p-3 start-0 end-0 ${imageColor} bg-opacity-75 text-white`}>
                            <div className="flex flex-col items-center justify-center w-full h-full p-6 border border-white">
                                <span className="pt-4 text-2xl font-bold">{title}</span>
                                <p className="mt-6 text-sm text-center">{imageDescription}</p>
                                <Link to={`/hizmetlerimiz/${service_id}`} className="py-3 duration-300 border border-white rounded-full px-9 mt-9 hover:bg-white hover:text-black">Detaylı Bilgi Alın</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ServiceCardList = () => {
    return (
        <>
            {
                serviceData.map((data, index) => (
                    <ServiceCard key={index} data={data} />
                ))
            }
        </>
    );
};

export default ServiceCardList;



