import { Link } from "react-router-dom";
import BlogBackground from "../assets/images/blog/blogbackground.png";
import ChangeSVG from "../helpers/ChangeSVG";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import Api from "../api/Api";


const Blog = () => {

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const blogs = await Api.get_blogs();
                setBlogs(blogs);
            } catch (error) {

            }
        };

        fetchData();
    }, []);

    const data = [
        { 'svg': 'specialdesign', 'title': 'Kurumsal Web Tasarımı' }, { 'svg': 'specialsupport', 'title': 'Özel Web Yazılımı' },
        { 'svg': 'phonedesign', 'title': 'Mobile Uygulama Tasarımı' }, { 'svg': 'socialmedia', 'title': 'Sosyal Medya Tasarımı' },
        { 'svg': 'softwarephone', 'title': 'Mobile Uygulama Yazılımı' }, { 'svg': 'seo', 'title': 'SEO Optimizasyon Hizmeti' },
    ];

    // const BlogData = [
    //     {
    //         'id': 'web-tasarim-onemi',
    //         'category': 'Web Tasarımı',
    //         'date': '',
    //         'title': 'Web Tasarım Neden Önemli?',
    //         'content': 'Başarılı bir web sitesi oluşturmak, günümüz dijital çağında işletmelerin başarısı için kritik bir öneme sahiptir. Ancak bir web sitesi sadece var olması değil, etkili bir şekilde tasarlanması ve yönetilmesi gereklidir. İşte bir web tasarımının neden önemli olduğuna dair bazı temel nedenler:',
    //         'image': 'blog01',
    //     },
    //     {
    //         'id': 'web-yazilim-onemi',
    //         'category': 'Web Yazılımı',
    //         'date': '',
    //         'title': 'Web Yazılımı Süreçleri Nelerdir?',
    //         'content': 'Web yazılımı, günümüz dijital çağında işletmelerin çevrimiçi varlığını güçlendirmek için önemli bir araç haline gelmiştir. Bir web uygulaması oluştururken, çeşitli süreçlerin izlenmesi gerekir.',
    //         'image': 'blog02',
    //     },
    //     {
    //         'id': 'mobil-uygulama-yazilimi',
    //         'category': 'Mobil Uygulama',
    //         'date': '',
    //         'title': 'Mobil Uygulama Geliştirme Nedir?',
    //         'content': 'Mobil teknolojilerin hızla gelişmesiyle birlikte, insanlar artık akıllı telefonlarını hayatlarının ayrılmaz bir parçası haline getirdiler. Bu durum, işletmeler için de yeni fırsatlar yarattı ve mobil uygulamalar, müşterilere daha yakın ve etkili bir şekilde ulaşmanın bir yolu haline geldi.',
    //         'image': 'blog03',
    //     },
    //     {
    //         'id': 'seo-optimizasyonu',
    //         'category': 'SEO Optimizasyonu',
    //         'date': '',
    //         'title': 'SEO Optimizasyonu Nedir?',
    //         'content': 'SEO (Arama Motoru Optimizasyonu), bir web sitesinin arama motorlarında daha üst sıralarda yer alması ve organik trafiği artırması için yapılan çeşitli tekniklerin ve stratejilerin bütünüdür. SEO optimizasyonu, web sitenizin içeriğini, yapısını ve bağlantılarını iyileştirerek, potansiyel müşterilerinizi çekmenizi ve çevrimiçi varlığınızı güçlendirmenizi sağlar.',
    //         'image': 'blog04',
    //     },
    //     {
    //         'id': 'ozel-web-yazilimi',
    //         'category': 'Web Yazılımı',
    //         'date': '',
    //         'title': 'Özel Web Yazılımı Neden Önemli?',
    //         'content': 'İşletmelerin dijital pazardaki varlığı, gün geçtikçe daha da önem kazanıyor. Birçok işletme, potansiyel müşterilere çevrimiçi olarak ulaşabilmek ve işlerini büyütebilmek için web sitelerini kullanıyor.',
    //         'image': 'blog05',
    //     },
    //     {
    //         'id': 'medya-tasarimi',
    //         'category': 'Medya Tasarımı',
    //         'date': '',
    //         'title': 'Sosyal Medya: İşletmeniz İçin Neden Önemli?',
    //         'content': 'Günümüzün dijital çağında, sosyal medya işletmeler için önemli bir pazarlama aracı haline gelmiştir. Sosyal medya platformları, işletmelerin potansiyel müşterilere ulaşmasını, marka bilinirliğini artırmasını ve müşteri ilişkilerini güçlendirmesini sağlar.',
    //         'image': 'blog06',
    //     },

    // ];

    return (
        <main>

            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/blog" />
                <title>Bloglarımız - Time Yazılım</title>
                <meta name="description" content="Time Yazılım'ın bloglarında, dijital dünyadaki son trendlerden, teknoloji haberlerine ve ipuçlarına kadar çeşitli konularda bilgi ve içerikler bulabilirsiniz. Bloglarımızı takip ederek işletmenizin dijital varlığını güçlendirecek stratejiler hakkında bilgi sahibi olabilir ve kendinizi geliştirebilirsiniz." />
            </Helmet>

            <div className="relative">
                <article className="container text-white pt-28">
                    <div className="px-8 lg:w-9/12 ms-auto">
                        <h1 className="text-3xl font-rubik">BLOGLARIMIZ</h1>
                        <span className="flex mt-3">Bloglarımız {'>'} Time Yazılım</span>
                    </div>
                    <div className="flex flex-col mt-12 md:flex-row gap-9">
                        <div className="lg:basis-3/12">
                            <div className="flex flex-col gap-4 categoryCard bg-[#21A0D9] bg-opacity-15 backdrop-blur-xl py-6 px-3 rounded-md border border-blue-500 border-opacity-25">
                                <div className="flex items-center gap-4 p-3 categoryItem bg-[#00AEEF] rounded-md bg-opacity-10 ">
                                    <ChangeSVG name={'category_menu'} height={24} width={24} color='#18E3FF' />
                                    <span className="text-sm">Tüm Referanslar</span>
                                </div>
                                {
                                    data.map((card, index) => (
                                        <div key={index} className="flex items-center gap-4 p-3 bg-white rounded-md opacity-50 categoryItem bg-opacity-10 hover:bg-[#00AEEF] duration-300 hover:bg-opacity-15 hover:opacity-100 hover:text-[#18E3FF] group">
                                            <div className="group-hover:fill-[#18E3FF] fill-white">
                                                <ChangeSVG name={card.svg} height={24} width={24} />
                                            </div>
                                            <span className="text-sm">{card.title}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="lg:basis-9/12">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                {
                                    blogs.map((blog, index) => (
                                        <div key={index} className="flex flex-col categoryCard bg-[#21A0D9] bg-opacity-15 backdrop-blur-xl rounded-md border border-blue-500 border-opacity-25">
                                            <div className="flex items-center justify-between px-5 pt-4">
                                                <div className="flex items-center gap-3">
                                                    <ChangeSVG name={'dotyellow'} width={26} height={26} color={'#FCC009'} />
                                                    <span className="text-xs text-[#FCC009]">{blog.blog_category}</span>
                                                </div>
                                                <div>
                                                    <span className="text-xs opacity-50">{blog.blog_date}</span>
                                                </div>
                                            </div>
                                            <div className="px-5 py-3 text-lg italic font-bold">
                                                <span>{blog.blog_title}</span>
                                            </div>
                                            <img src={`http://localhost:8888/timeyazilim/api/asset/uploads/blogs/${blog.blog_photo}`} alt="Blog Görseli" />
                                            <div className="px-4 py-5">
                                                <p className="text-xs opacity-75 line-clamp-3">{blog.blog_description}</p>
                                            </div>
                                            <Link to={`/blog/${blog.blog_id}`} className="flex items-center gap-3 px-4 mb-4">
                                                <ChangeSVG name="arrowoutline" width={16} height={16} color="#18E3FF" />
                                                <span className="text-[#18E3FF] font-medium text-xs mb-[2px]">Blogu Görüntüle</span>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </article>

                <section className="fixed top-0 bottom-0 end-0 start-0 -z-10">
                    <img className="object-cover w-full h-full" src={BlogBackground} alt="" />

                </section>
            </div>
            <section className="py-10"></section>
        </main>
    )
}

export default Blog;