import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";

const HalfBannerData = [
    {
        title: 'HAKKIMIZDA',
        content: 'Time Yazılım, müşterilere yenilikçi çözümler sunan bir yazılım şirketidir. Müşteri odaklı yaklaşımımız ve yüksek kaliteli hizmet anlayışımızla, işletmelerin dijital dönüşüm süreçlerine liderlik ediyoruz. Uzman ekip arkadaşlarımızla birlikte, her bir projede en iyi sonuçları elde etmek için çalışıyoruz.',
        subtitle: 'Yenilikçi Yaklaşımlarımızla Fark Yaratmaya Devam Ediyoruz',
        image: 'hakkimizdabanner',
        image_page: 'hakkimizda'
    },
    {
        title: 'KARİYER',
        content: 'TIME, dinamik ve yetenekli bireylerle birlikte çalışarak başarıyı hedefleyen bir ekibe sahiptir. Kariyer sayfamızda, farklı uzmanlık alanlarında kariyer yapma fırsatı sunuyoruz. Ekip çalışması ve sürekli öğrenme kültürümüzle, çalışanlarımızın kişisel ve mesleki gelişimine önem veriyoruz Eğer birlikte çalışma ve kendini geliştirme fırsatı arıyorsanız, bize katılmak için doğru adrestesiniz.',
        subtitle: 'Kariyer Basamaklarınıza Time’da Adım Atın.',
        image: 'kariyerbanner',
        image_page: 'kariyer'
    },
    {
        title: 'MİSYON & VİZYON',
        content: 'TIME, müşterilerine yenilikçi çözümler sunmayı, ihtiyaçlarını en üst düzeyde karşılamayı ve teknolojiyi kullanarak dijitalleşmeyi kolaylaştırmayı hedefler. Vizyonumuz, dünya çapında etki yaratan çözümler geliştirerek markaların dikital dönüşümlerini gerçekleştirerek dijital dünyada ki varlığını ve verimliliğini arttırmaktır. ',
        subtitle: 'Geleceği Şekillendirirken Kendimizi Biçimlendiriyoruz.',
        image: 'misyonbanner',
        image_page: 'misyon'
    },
    {
        title: 'HİZMETLERİMİZ',
        content: 'Time Yazılım olarak, müşterilerimize çağın gereksinimlerine uygun kapsamlı hizmetler sunuyoruz. Web, mobil, sosyal medya ve daha fazlasıyla ilgili geniş bir yelpazede hizmetlerimiz bulunmaktadır. Her biri uzmanlık alanlarımızda deneyimli ekiplerimizle, müşterilerimizin dijital varlıklarını en üst düzeye çıkarmak için çabalıyoruz.',
        subtitle: 'Her Sektörün İhtiyaçlarına Özel Çözümler Sunuyoruz.',
        image: 'hizmetbanner',
        image_page: 'hizmetlerimiz'
    },
    {
        title: 'BİZİME İLETİŞİM KURUN',
        content: 'Firmanızın ihtiyaçlarına uygun özel çözümler mi arıyorsunuz? İşletmenizin dijital varlığını güçlendirmek veya mevcut sistemlerinizi iyileştirmek mi istiyorsunuz? O zaman doğru yerdesiniz! Time Yazılım olarak, müşterilerimize yenilikçi ve özelleştirilmiş çözümler sunmaktan ve onların hedeflerine ulaşmalarına yardımcı olmaktan gurur duyuyoruz.',
        subtitle: 'İş Ortaklığı, Fikir ve Önerilerinizi Paylaşabilirsiniz.',
        image: 'iletisimbanner',
        image_page: 'anasayfa'
    }
];


const HalfBanner = ({ page }) => {

    const data = HalfBannerData.find(item => item.title === page);

    if (!data) {
        return null;
    }

    const { title, content, subtitle, image, image_page } = data;

    return (
        <section className="relative">
            <ChangeIMG page={image_page} name={image} alt={title} height={'banner'} />
            <div className="absolute top-0 bottom-0 left-0 right-0">
                <div className="container w-full h-full">
                    <div className="flex flex-col items-start justify-center h-full text-white lg:w-7/12 lg:mt-9">
                        <span className="text-[#00B1FF] font-bold italic">Zamanın Ötesine!</span>
                        <h1 className="mt-4 text-3xl font-rubik">{title}</h1>
                        <p className="mt-3 text-sm opacity-50 lg:mt-9">{content}</p>
                        <div className="flex items-center gap-3 mt-9">
                            <ChangeSVG name={'dot'} height={26} width={26} color={''} />
                            <span className="text-sm font-semibold">{subtitle}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HalfBanner;