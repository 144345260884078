

import { Link, useLocation } from "react-router-dom";
import ChangeSVG from "../helpers/ChangeSVG";
import { useEffect, useState } from "react";

const UserHeader = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <>
            <header className="nav-top bg-[#FFC107] hidden lg:flex">
                <div className="container flex items-center justify-between py-2 text-sm">
                    <span className="font-semibold">DİJİTAL ÇÖZÜM DENEYİMİNİZİ ZAMANIN ÖTESİNE TAŞIYORUZ!</span>
                    <div className="flex gap-9">
                        <div className="flex items-center gap-2">
                            <ChangeSVG name="location" width={16} height={16} color="#000" />
                            <span>Bilişim Vadisi Gebze / Kocaeli</span>
                        </div>
                        <div onClick={(e) => { window.location.href = 'mailto:info@timeyazilim.com.tr'; }} className="flex items-center gap-2 cursor-pointer">
                            <ChangeSVG name="envelope" width={16} height={14} color="#000" />
                            <span>info@timeyazilim.com.tr</span>
                        </div>
                        <ul className="flex items-center justify-center gap-2">
                            {/* <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="facebook" width={20} height={20} color="#000" /></Link></li> */}
                            <li><Link target="_blank" to="https://www.instagram.com/timeyazilimm?igsh=MXJ4dzdhdDFpbW51bg%3D%3D&utm_source=qr"><ChangeSVG name="instagram" width={20} height={20} color="#000" /></Link></li>
                            {/* <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="twitter" width={20} height={20} color="#000" /></Link></li> */}
                            <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="linkedin" width={20} height={20} color="#000" /></Link></li>
                        </ul>
                    </div>
                </div>
            </header>

            {/* Navbar */}
            <nav className={`${isScrolled ? 'fixed left-0 right-0 z-50 scrolled  bg-opacity-85 bg-[#072140]' : 'absolute start-0 end-0  bg-opacity-15 bg-[#00B1FF]'} navbar backdrop-blur-md text-white text-sm z-50 hidden lg:flex`}>
                <div className="container flex items-center justify-between py-3">
                    <Link to="/" className="mt-1 Logo">
                        <ChangeSVG name="logo" width={80} height={36} color={""} />
                    </Link>
                    <ul className="flex gap-9">
                        <li className={pathname === '/' ? "opacity-100 text-[#FFC107]" : "opacity-75 hover:opacity-100 duration-300"}>
                            <Link to="/">Anasayfa</Link>
                        </li>
                        <li>
                            <div className="relative flex items-center gap-2 group">
                                <div className={(pathname === '/hakkimizda' || pathname === '/kariyer' || pathname === '/misyon') ? "opacity-100 text-[#FFC107] flex items-center gap-2" : "opacity-75 hover:opacity-100 duration-300 flex items-center gap-2"}>
                                    <span>Kurumsal</span>
                                    <ChangeSVG name="arrowdown" width={12} height={10} color={(pathname === '/hakkimizda' || pathname === '/kariyer' || pathname === '/misyon') ? '#FFC107' : '#fff'} />
                                </div>
                                <div className="absolute top-0 hidden group-hover:flex">
                                    <div className="pt-12">
                                        <div className="w-[200px] text-black bg-white dropdown flex flex-col gap-2 overflow-hidden rounded-sm -translate-x-10 group-hover:translate-x-0">
                                            <Link to='/hakkimizda' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                <span>Hakkımızda</span>
                                            </Link>
                                            <Link to='/kariyer' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                <span>Kariyer</span>
                                            </Link>
                                            <Link to='/misyon' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                <span>Misyon & Vizyon</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={pathname === '/projelerimiz' ? "opacity-100 text-[#FFC107]" : "opacity-75 hover:opacity-100 duration-300"}>
                            <Link to="/projelerimiz">Projelerimiz</Link>
                        </li>
                        <li>
                            <div className="relative flex items-center gap-2 group">
                                <div className={(pathname === '/hizmetlerimiz' || pathname === '/hizmetlerimiz/web-yazilim' || pathname === '/hizmetlerimiz/web-tasarim' || pathname === '/hizmetlerimiz/seo' || pathname === '/hizmetlerimiz/mobil-uygulama-tasarimi' || pathname === '/hizmetlerimiz/mobil-uygulama-yazilimi' || pathname === '/hizmetlerimiz/sosyal-medya-tasarimi') ? "opacity-100 text-[#FFC107]" : "opacity-75 hover:opacity-100 duration-300"}>
                                    <Link to="/hizmetlerimiz" className="flex items-center gap-2">
                                        <div>Hizmetlerimiz</div>
                                        <ChangeSVG name="arrowdown" width={12} height={10} color={(pathname === '/hizmetlerimiz' || pathname === '/hizmetlerimiz/web-yazilim' || pathname === '/hizmetlerimiz/web-tasarim' || pathname === '/hizmetlerimiz/seo' || pathname === '/hizmetlerimiz/mobil-uygulama-tasarimi' || pathname === '/hizmetlerimiz/mobil-uygulama-yazilimi' || pathname === '/hizmetlerimiz/sosyal-medya-tasarimi') ? '#FFC107' : '#fff'} />
                                    </Link>
                                    <div className="absolute hidden top-5 group-hover:flex">
                                        <div className="pt-6">
                                            <div className="w-[200px] text-black bg-white dropdown flex flex-col gap-2 overflow-hidden rounded-sm -translate-x-10 group-hover:translate-x-0">
                                                <Link to='/hizmetlerimiz/web-yazilim' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                    <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                    <span>Özel Web Yazılımı</span>
                                                </Link>
                                                <Link to='/hizmetlerimiz/web-tasarim' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                    <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                    <span>Kurumsal Web Tasarımı</span>
                                                </Link>
                                                <Link to='/hizmetlerimiz/seo' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                    <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                    <span>Seo Optimizasyonu</span>
                                                </Link>
                                                <Link to='/hizmetlerimiz/mobil-uygulama-tasarimi' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                    <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                    <span>Mobil Uygulama Tasarımı</span>
                                                </Link>
                                                <Link to='/hizmetlerimiz/mobil-uygulama-yazilimi' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                    <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                    <span>Mobil Uygulama Yazılımı</span>
                                                </Link>
                                                <Link to='/hizmetlerimiz/sosyal-medya-tasarimi' className="flex items-center gap-3 font-medium duration-300 opacity-25 hover:opacity-100">
                                                    <div className="w-1 h-8 bg-[#FFC107]"></div>
                                                    <span>Sosyal Medya Post Tasarımı</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>
                        <li className={pathname === '/blog' ? "opacity-100 text-[#FFC107]" : "opacity-75 hover:opacity-100 duration-300"}>
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li className={pathname === '/iletisim' ? "opacity-100 text-[#FFC107]" : "opacity-75 hover:opacity-100 duration-300"}>
                            <Link to="/iletisim">İletişim</Link>
                        </li>
                    </ul>
                </div>
            </nav>

            {/* Mobile Navbar */}
            <nav className="sticky top-0 z-50 block lg:hidden">
                <div className="relative">
                    <div className="bg-[#FFC107] py-4 px-4">
                        <div className="flex items-center justify-between navHead">
                            <Link to='/'>
                                <ChangeSVG name={'blacklogo'} width={90} height={36} color='#000' />
                            </Link>
                            <div className="mb-2" onClick={toggleMenu}>
                                <ChangeSVG name={'navMenu'} width={24} height={24} color='#000' />
                            </div>
                        </div>
                    </div>
                    {isMenuOpen && (
                        <div className="absolute bottom-0 w-full h-screen top-[4.25rem] start-0">
                            <div className="bg-[#06192A] w-3/4 h-full text-white py-6 px-4">
                                <div className="flex items-center justify-between opacity-50">
                                    <span>Hızlı Erişim Sağlayın</span>
                                    <span>3</span>
                                </div>
                                <div className="flex flex-col gap-4 mt-6">
                                    <Link to='/' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Anasayfa</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/Hizmetlerimiz' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Hizmetlerimiz</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/projelerimiz' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Projelerimiz</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <div className="flex items-center justify-between mt-12 opacity-50">
                                        <span>Diğer Bağlantılarımız</span>
                                        <span>2</span>
                                    </div>

                                    <Link to='/blog' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>Blog</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <Link to='/iletisim' onClick={toggleMenu} className="flex items-center justify-between px-2 py-2 duration-300 bg-white rounded-md opacity-75 bg-opacity-10">
                                        <span>İletişim</span>
                                        <div className="-rotate-90">
                                            <ChangeSVG name={'arrowdown'} width={16} height={16} color='#fff' />
                                        </div>
                                    </Link>
                                    <div className="mt-4">
                                        <ul className="flex items-center justify-start gap-2">
                                            {/* <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="colorfulfacebook" width={20} height={20} color="#000" /></Link></li> */}
                                            <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="colorfulinstagram" width={20} height={20} color="#000" /></Link></li>
                                            {/* <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="colorfultwitter" width={20} height={20} color="#000" /></Link></li> */}
                                            <li><Link target="_blank" to="https://www.linkedin.com/company/time-yaz%C4%B1l%C4%B1m"><ChangeSVG name="colorfullinkedin" width={20} height={20} color="#000" /></Link></li>
                                        </ul>
                                    </div>
                                    <span className="text-xs opacity-50">Sosyal Medyadan Bizi Takip Edin!</span>
                                    <div className="mt-8">
                                        <span className="font-bold opacity-25">TIME YAZILIM TEKNOLOJİ A.Ş</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        </>
    )
}

export default UserHeader;