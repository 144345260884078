import ChangeSVG from "../helpers/ChangeSVG";


const misyonData = [
    {
        title: 'Yenilikçi ve Özelleştirilmiş Çözümler',
        content: 'Teknolojiye olan hakimiyeti sayesinde, işletmelerin dijital dönüşüm sürecinde öncü bir rol oynar.',
        svg: 'specialsocial',
        color: 1
    },
    {
        title: 'Yüksek Kalite Standartları',
        content: 'Her bir proje için titizlikle çalışarak, kaliteden ödün vermez ve en iyi sonuçları elde etmeyi amaçlar.',
        svg: 'quality',
        color: 2
    },
    {
        title: 'İnovatif ve Dijital Çözümler',
        content: 'Her bir projeye özel olarak tasarlanan inovatif çözümlerimizle, müşterilerin ihtiyaçlarını karşılamayı hedefliyoruz.',
        svg: 'rocket',
        color: 1
    },
    {
        title: 'Yüksek Müşteri Memnuniyeti',
        content: 'Güçlü iletişim ve destek ekibiyle, müşteri memnuniyetini en üst seviyede tutmayı amaçlar.',
        svg: 'customer',
        color: 2
    },

];


const MisyonCard = ({ data }) => {

    const { title, content, svg, color } = data;

    return (
        <div className="flex flex-col items-center gap-6 p-6 bg-white rounded-md md:flex-row skillCard ">
            <div className={`${color === 1 ? 'bg-[#FFC107]' : 'bg-[#21A0D9]'}  bg-opacity-25 p-3 flex items-center justify-center rounded-md`}>
                <ChangeSVG className="p-5" name={svg} width={72} height={72} color="#000" />
            </div>
            <div>
                <span className="text-lg italic font-bold">{title}</span>
                <p className="mt-2 text-sm opacity-75">{content}</p>
            </div>
        </div>
    )
}

const MisyonCardList = () => {

    return (
        <>
            {
                misyonData.map((data, index) => (
                    <MisyonCard key={index} data={data} />
                ))
            }
        </>
    )
}

export default MisyonCardList;