
import BlogBackground from "../assets/images/blog/blogbackground.png";
import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";
import { Helmet } from 'react-helmet';


const Projelerimiz = () => {

    const data = [
        { 'svg': 'specialdesign', 'title': 'Kurumsal Web Tasarımı' }, { 'svg': 'specialsupport', 'title': 'Özel Web Yazılımı' },
        { 'svg': 'phonedesign', 'title': 'Mobile Uygulama Tasarımı' }, { 'svg': 'socialmedia', 'title': 'Sosyal Medya Tasarımı' },
        { 'svg': 'softwarephone', 'title': 'Mobile Uygulama Yazılımı' }, { 'svg': 'seo', 'title': 'SEO Optimizasyon Hizmeti' },
    ];

    const BusinessData = [
        {
            'title': 'Palet Merkezi ',
            'jobs': ['B2B Projesi', 'Web Tasarım', 'E-Ticaret'],
            'content': 'Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.',
            'image': 'referanspalet',
            'url': 'https://paletmerkezi.com/'
        },
        {
            'title': 'Bilginoğulları ',
            'jobs': ['Web Tasarım', 'Web Yazılımı', 'Panel Yönetimi'],
            'content': 'Müşterimizin ihtiyaçlarına uygun modern bir tasarım oluşturarak, kullanıcıların siteyi kolayca gezmesini ve ürünleri keşfetmesini sağladık. Aynı zamanda, kaliteyi yansıtan bir kimlik oluşturarak marka imajını güçlendirdik.',
            'image': 'referansbilgin',
            'url': 'https://bilginogullari.com/'
        },
        {
            'title': 'Feksan Makine',
            'jobs': ['Web Tasarım', 'Web Yazılımı', 'Responsive Yazılım'],
            'content': 'Endüstriyel makinelerin üretimi ve satışı konusunda uzmanlaşmış olan Feksan Makine için, modern ve işlevsel bir web sitesi oluşturduk. Web sitesi, ürün yelpazesini etkili bir şekilde sergiler ve detaylı bir bilgi sunar.',
            'image': 'referansfeksan',
            'url': 'https://feksanmakina.com/'
        },
        {
            'title': 'Biltaş Orman Ürünleri',
            'jobs': ['Web Tasarım', 'Web Yazılımı', 'Responsive Yazılım'],
            'content': 'iltaş Orman Ürünleri, modern ve şık tasarımıyla göz dolduran bir web sitesiyle karşınızda. Müşterilerin ihtiyaçlarına uygun özelleştirilmiş çözümler sunan Time Yazılım tarafından yapılan web çözümleri biltaş için hazırlandı.',
            'image': 'referansbiltas',
            'url': 'https://biltasentegre.com/'
        },
        {
            'title': 'Güzeltepe Metal',
            'jobs': ['Web Tasarım', 'Web Yazılımı', 'Responsive Yazılım'],
            'content': 'Time Yazılım olarak, Güzeltepe Metalin ihtiyaçları doğrultusunda modern ve kullanıcı dostu bir web sitesi oluşturduk. Kullanıcı deneyimini ön planda tutarak, siteyi erişilebilir hale getirdik.',
            'image': 'referansguzeltepe',
            'url': 'http://guzeltepemetal.com/'
        },
    ];

    return (
        <main>

            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/projelerimiz" />
                <title>Projelerimiz - Time Yazılım</title>
                <meta name="description" content="Time Yazılım olarak, müşterilerimize özel çözümler sunmaktan gurur duyuyoruz. Referanslarımız arasında yer alan çeşitli projelerle, işletmelerin dijital ihtiyaçlarını karşılıyor ve onların başarılarına katkıda bulunuyoruz. Web tasarımı, web yazılımı, mobil uygulama tasarımı ve yazılımı gibi birçok alanda sunduğumuz hizmetlerle, müşterilerimizin beklentilerini en iyi şekilde karşılıyoruz." />
            </Helmet>


            <div className="relative">
                <article className="container pt-12 text-white lg:pt-28">
                    <div className="px-8 lg:w-9/12 ms-auto">
                        <h1 className="text-3xl font-rubik">PROJELERİMİZ</h1>
                        <span className="flex mt-3">Projelerimiz {'>'} Web Tasarımı</span>
                    </div>
                    <div className="flex flex-col mt-12 md:flex-row gap-9">
                        <div className="lg:basis-3/12">
                            <div className="flex flex-col gap-4 categoryCard bg-[#21A0D9] bg-opacity-15 backdrop-blur-xl py-6 px-3 rounded-md border border-blue-500 border-opacity-25">
                                <div className="flex items-center gap-4 p-3 categoryItem bg-[#00AEEF] rounded-md bg-opacity-10 ">
                                    <ChangeSVG name={'category_menu'} height={24} width={24} color='#18E3FF' />
                                    <span className="text-sm">Tüm Referanslar</span>
                                </div>
                                {
                                    data.map((card, index) => (
                                        <div key={index} className="flex items-center gap-4 p-3 bg-white rounded-md opacity-50 categoryItem bg-opacity-10 hover:bg-[#00AEEF] duration-300 hover:bg-opacity-15 hover:opacity-100 hover:text-[#18E3FF] group">
                                            <div className="group-hover:fill-[#18E3FF] fill-white">
                                                <ChangeSVG name={card.svg} height={24} width={24} />
                                            </div>
                                            <span className="text-sm">{card.title}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="lg:basis-9/12">
                            <div className="jobsCard flex flex-col gap-8 categoryCard bg-[#21A0D9] bg-opacity-15 backdrop-blur-xl p-5 rounded-md border border-blue-500 border-opacity-25">
                                {
                                    BusinessData.map((refCard, index) => (
                                        <div key={index} className="flex flex-col md:flex-row gap-4 items-center jobCard bg-[#21A0D9]  bg-opacity-5 rounded-md border border-blue-500 border-opacity-10">
                                            <div className="lg:basis-5/12">
                                                <ChangeIMG page={'anasayfa'} name={refCard.image} alt={'PaletMerkezi'} />
                                            </div>
                                            <div className="lg:py-6 basis-7/12">
                                                <span className="text-2xl italic font-bold">{refCard.title}</span>
                                                <div className="flex flex-col items-start gap-3 mt-4 mb-6 lg:items-center md:flex-row">
                                                    {
                                                        refCard.jobs.map((item, index) => (
                                                            <div key={index} className="flex items-center gap-2">
                                                                <ChangeSVG name="dotyellow" width={24} height={24} color="" />
                                                                <span className="text-sm opacity-75">{item}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <p className="text-xs opacity-50 pe-8">{refCard.content}</p>
                                                <a className="bg-[#FFC107] inline-flex mt-6 text-xs rounded-md text-black p-2 font-bold" target='_blank' rel="noopener noreferrer" href={`${refCard.url}`} >PROJEYİ GÖRÜNTÜLE</a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </article>

                <section className="fixed top-0 bottom-0 end-0 start-0 -z-10">
                    <img className="object-cover w-full h-full" src={BlogBackground} alt="" />

                </section>
            </div>
            <section className="py-10"></section>
        </main>
    )
}

export default Projelerimiz;