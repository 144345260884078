import { Helmet } from "react-helmet";
import HalfBanner from "../components/HalfBanner";
import ServiceCardList from "../components/ServiceCard";
import ContactCard from "../partials/ContactCard";

const Hizmetlerimiz = () => {
    return (
        <main>

            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/hizmetlerimiz" />
                <title>Hizmetlerimiz - Time Yazılım</title>
                <meta name="description" content="Time Yazılım olarak, müşterilerimize geniş bir yelpazede profesyonel hizmetler sunuyoruz. Web tasarımı, web yazılımı, mobil uygulama tasarımı ve yazılımı, SEO optimizasyonu ve sosyal medya tasarımı gibi alanlarda uzman ekibimizle işletmelerin dijital ihtiyaçlarını karşılıyoruz. Müşterilerimizin beklentilerini en iyi şekilde karşılamak ve işletmelerini dijital dünyada güçlendirmek için buradayız." />
            </Helmet>


            <HalfBanner page={"HİZMETLERİMİZ"} />

            <section className="pt-20 bg-white">
                <article className="container">
                    <div className="flex flex-col justify-between gap-4 lg:items-center lg:flex-row lg:gap-9">
                        <div className="lg:w-4/12">
                            <h3 className="text-2xl lg:text-5xl font-rubik">HİZMETLERİMİZ</h3>

                        </div>
                        <div className="lg:w-8/12">
                            <p className="text-xs">Time Yazılım, müşterilerine geniş bir yelpazede yazılım çözümleri sunmaktadır. Müşterilerimize ihtiyaçlarına özel çözümler sunarak, verimliliği artırmalarına, maliyetleri düşürmelerine ve rekabet avantajı elde etmelerine yardımcı oluyoruz.</p>
                        </div>
                    </div>
                </article>
                <article className="container mt-12">
                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <ServiceCardList />
                    </div>
                </article>
            </section>

            <section className="py-20 bg-white">
                <article className="container">
                    <ContactCard />
                </article>
            </section>

        </main>
    );
}

export default Hizmetlerimiz;