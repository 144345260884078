import { Link } from "react-router-dom";

const kariyerData = [
    {
        title: 'Kullanıcı Arayüz Tasarımcısı',
        work: 'Kocaeli Gebze / Hibrit',
        content: 'TIME, Kocaeli Gebze deki ekip içi etkileşim ve işbirliğini önemseyen bir Kullanıcı Arayüz Tasarımcısı arıyor.Hibrit çalışma modelimizle, ofis ve uzaktan çalışma imkanları sunarak esneklik sağlıyoruz.'
    },
    {
        title: 'Web Yazılım Geliştiricisi',
        work: 'Kocaeli Gebze / Hibrit',
        content: 'Front-end ve Back-end teknolojilerine hakimiz, kullanıcı deneyimini ve performansını optimize eden çözümler geliştiriyoruz. Çeşitli projelerde yer almak istiyorsanız, başvurunuzu bekliyoruz!'
    },
    {
        title: 'Mobil Uygulama Geliştiricisi',
        work: 'Kocaeli Gebze / Hibrit',
        content: 'iOS ve/veya Android platformlarında güçlü deneyime sahip olarak, kullanıcı ihtiyaçlarını karşılayan ve en son teknolojileri takip eden uygulamalar üreten biriyseniz cv’nizi bize iletmekten çekinmeyin.'
    },
]

const KariyerCard = ({ data }) => {

    const { title, work, content } = data;

    return (
        <div className="px-8 py-6 bg-white kariyerCard">
            <div className="flex flex-col items-center justify-between md:flex-row">
                <div>
                    <div className="flex flex-col items-center gap-4 text-xl italic font-bold md:flex-row">
                        <span>{title}</span>
                        <span className="text-[#FFC107]">{work}</span>
                    </div>
                    <p className="mt-6 text-sm lg:w-8/12">{content}</p>
                </div>
                <div className="mt-8 lg:mt-0">
                    <Link onClick={(e) => { window.location.href = 'mailto:info@timeyazilim.com.tr'; }} className='bg-[#FFC107] py-4 px-8 rounded-full font-semibold text-sm'>BAŞVUR</Link>
                </div>
            </div>
        </div>
    )
}

const KariyerCardList = () => {
    return (
        <>
            {
                kariyerData.map((data, index,) => (
                    <KariyerCard key={index} data={data} />
                ))
            }
        </>
    )
}

export default KariyerCardList;