import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const SwiperSlider = ({ slides, modules = [Navigation, Pagination, Scrollbar, Autoplay], padding = 0, centered = false, centeredBounds = false, spaceBetween = 0, slideView = 1, initialSlide = 0, loop = false, delay = 2000, speed = 1000, StartView = null }) => {

    const py = `py-[${padding}px]`

    return (
        <Swiper
            modules={modules}
            centeredSlides={centered}
            centeredSlidesBounds={centeredBounds}
            spaceBetween={spaceBetween}
            slidesPerView={slideView}
            initialSlide={initialSlide}
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            loop={loop}
            autoplay={{
                delay: delay,
                disableOnInteraction: false,
                speed: speed
            }}
            pagination={{ clickable: true }}
            breakpoints={{
                768: {
                    slidesPerView: slideView,
                },
                0: {
                    slidesPerView: 1,
                },
            }}
        >
            {
                slides.map((slide, index) => (
                    <SwiperSlide className={py} key={index}>{slide}</SwiperSlide>
                ))
            }
        </Swiper>
    );
};

export default SwiperSlider;