import React from 'react';
import ChangeSVG from '../helpers/ChangeSVG';

function Accordion({ title, content, isOpen, onClick }) {
    return (
        <div className="accordion">
            <div
                className="accordion-header bg-[#FAFAFA] flex items-center p-3 rounded-md justify-between"
                onClick={onClick}
            >
                <span className='font-semibold'>{title}</span>
                <ChangeSVG name={isOpen ? 'minus' : 'pluse'} width={16} height={16} color={'#21A0D9'} />
            </div>
            {isOpen && (
                <div className="flex items-center mt-6 accordion-content">
                    <div className="border border-l border-[#21A0D9] h-20 mx-5"></div>
                    <p className='text-sm'>{content}</p>
                </div>
            )}
        </div>
    );
}

export default Accordion;
