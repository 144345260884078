import { Link } from "react-router-dom";
import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from "../helpers/ChangeSVG";


const BlogCard = ({ title, content, image, date, link }) => {

    return (
        <div className="relative h-full overflow-hidden blogCard group">
            <ChangeIMG page={'anasayfa'} name={image} alt={'Yazılım Geliştirme'} />
            <div className="absolute top-0 bottom-0 start-0 end-0">
                <div className="flex flex-col group-hover:justify-between h-full p-3 text-white transition-all duration-700 bg-black translate-y-[7.75rem] md:translate-y-[11.75rem] group-hover:translate-y-0 backdrop-blur-xl bg-opacity-20 group-hover:bg-opacity-60">
                    <div className="flex items-center gap-2">
                        <ChangeSVG name="date" width={14} height={14} color="#fff" />
                        <div className="mt-[1px] text-xs font-semibold opacity-50 date">{date}</div>
                    </div>
                    <div className="mt-2 group-hover:mt-0">
                        <span className="text-lg italic font-bold group-hover:text-[#FFC107] duration-300">{title}</span>
                        <p className="mt-4 text-xs opacity-75">{content}</p>
                    </div>
                    <div className="mt-8 group-hover:mt-0">
                        <Link to={link} className="bg-[#FFC107] text-xs font-semibold py-2 px-3 text-black rounded-md inline-flex">Blogumuzu Görüntüleyin</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCard;