import { Link } from 'react-router-dom';
import ErrorBackground from '../assets/images/anasayfa/404.png';
import ChangeSVG from '../helpers/ChangeSVG';

const ErrorPage = () => {

    return (
        <main className='relative'>
            <img className='h-[calc(100vh-2rem)]' src={ErrorBackground} alt="" />
            <div className="absolute top-0 bottom-0 start-0 end-0">
                <div className="container flex flex-col items-center justify-center w-full h-full text-white">
                    <div className='mt-24'>
                        <ChangeSVG name={'code'} />
                    </div>
                    <span className='mt-6 text-xl italic font-bold'><span className=''>404 Error - Aradığınız Sayfa Bulunamadı.</span> <span className='opacity-25'>- Güncelleniyor Olabilir</span></span>
                    <Link className='bg-[#FFC107] text-black py-2 px-4 rounded-full mt-6 font-semibold' to='/'>Anasayfaya Dönün</Link>
                </div>
            </div>
        </main>
    )
}

export default ErrorPage;