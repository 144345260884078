import ChangeSVG from "../helpers/ChangeSVG";
import ChangeIMG from '../helpers/ChangeIMG';
import { Link } from "react-router-dom";
import ContactCard from "../partials/ContactCard";
import HalfBanner from "../components/HalfBanner";
import { Helmet } from 'react-helmet';

const Hakkimizda = () => {

    return (
        <main>
            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/hakkimizda" />
                <title>Biz Kimiz? - Time Yazılım</title>
                <meta name="description" content="Time Yazılım olarak, müşterilerimize özel çözümler sunan, teknolojiyi en iyi şekilde kullanarak işlerinizi kolaylaştıran bir yazılım firmasıyız. Profesyonel ekip arkadaşlarımız ve yenilikçi yaklaşımımızla, sizinle birlikte başarılı projelere imza atmak için buradayız." />
            </Helmet>

            <HalfBanner page={"HAKKIMIZDA"} />

            {/* Inovation Section */}
            <section className="pt-14">
                <article className="container">
                    <div className="flex flex-col items-center gap-5 md:flex-row">
                        <div className="lg:basis-7/12">
                            <ChangeIMG page={'hakkimizda'} name={'hakkimizdatasari'} alt={'time yazilim hakkimizda'} rounded={'md'} />
                        </div>
                        <div className="lg:basis-5/12">
                            <h2 className="lg:w-9/12 text-4xl leading-[3rem] flex flex-col"><span className="font-light">Tasarım ve Yazılım Deneyiminizi</span> <span className="italic font-extrabold">Zamanın Ötesine Taşıyoruz!</span></h2>
                            <p className="mt-10">Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.</p>
                            <div className="mt-12">
                                <Link to="/iletisim" className="px-6 py-3 text-sm font-semibold text-white bg-black rounded-md">Bizi Yakından Tanıyın </Link>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="container py-20">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-9">
                        <div className="flex items-center p-6 bg-white rounded-md card gap-9">
                            <ChangeSVG name={'specialsocial'} width={200} height={100} color="#FFC107" />
                            <div>
                                <span className="text-xl italic font-extrabold">İNOVASYON</span>
                                <p className="mt-3 text-sm font-medium">En gelişmiş teknolojileri ve en trend tasarımları sürekli takip eder ve adapte olmaya çalışır, müşterilerimize değer katarız.</p>
                            </div>
                        </div>
                        <div className="relative flex items-center p-6 rounded-md card gap-9 bg-gradient-to-tr from-[#5DC0EC] via-[#5DC0EC] to-[#00B0FF] text-white overflow-hidden">
                            <ChangeSVG name={'like'} width={200} height={110} color="#fff" />
                            <div className="z-10">
                                <span className="text-xl italic font-extrabold">MEMNUNİYET</span>
                                <p className="mt-3 text-sm font-medium">Müşterilerimizin beklentilerini anlamak ve onların ihtiyaçlarına uygun çözümler sunmak için çaba gösteriyoruz.</p>
                            </div>
                            <div className="absolute bottom-0 right-0">
                                <ChangeSVG name={'halflike'} width={''} height={''} color="#fff" />
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            {/* Location Section */}
            <section>
                <article className="container">
                    <h3 className="text-4xl text-center font-rubik">BİLİŞİM VADİSİNDEN DESTEK VERİYORUZ</h3>
                    <div className="relative mt-9">
                        <ChangeIMG page={'hakkimizda'} name={'bilisimvadisi'} height={'banner'} alt={'Bilişim Vadisi Time Yazılım'} rounded={'md'} />
                        <div className="absolute bottom-0 right-0 p-3 ">
                            <div className="w-[16.25rem] h-[16.25rem] px-4 py-6 text-white bg-black bg-opacity-25 rounded-md opakCard backdrop-blur-xl border border-white border-opacity-50">
                                <span className="font-black">LOKASYONUMUZ</span>
                                <p className="mt-3 text-xs">Muallimköy Mahallesi, Deniz Cd. No:143-5, 41400 Gebze/Kocaeli</p>
                                <div className='flex items-center gap-2 mt-5 text-xs'>
                                    <ChangeSVG name="location" width={16} height={16} color="#fff" />
                                    <span>Bilişim Vadisi Gebze / Kocaeli</span>
                                </div>
                                <div className='flex items-center gap-2 mt-5 text-xs'>
                                    <ChangeSVG name="phone" width={16} height={16} color="#fff" />
                                    <div className='flex items-center gap-2'><span>+90 0541 843 43 13 </span> <span className='opacity-25'>Bize Ulaşın!</span></div>
                                </div>
                                <span className="inline-flex mt-5 text-xs opacity-50">ZAMANIN ÖTESİNE TAŞIYALIM!</span>
                                <div className="flex items-end justify-end mt-3">
                                    <ChangeSVG name="logo" width={60} height={24} color="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            {/* Contact Section */}
            <section className="py-20">
                <article className="container">
                    <ContactCard />
                </article>
            </section>
        </main>
    )

}

export default Hakkimizda;