
import main from "../assets/images/anasayfa/main.png";

import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

import ChangeIMG from "../helpers/ChangeIMG";
import ChangeSVG from '../helpers/ChangeSVG';
import SwiperSlider from "../components/SwiperSlider";

import StandSlide from "../components/StandSlide";
import BannerSlide from '../components/BannerSlide';

import ServiceCardList from "../components/ServiceCard";
import BusinessCard from "../components/BusinessCard";
import BlogCard from "../components/BlogCard";

import { Helmet } from 'react-helmet';


import ContactCard from '../partials/ContactCard';


const Home = () => {

    return (
        <main>
            <Helmet>
                <link rel="canonical" href="https://timeyazilim.com.tr/" />
                <title>Anasayfa - Time Yazılım</title>
                <meta name="description" content="Tek bir noktadan tüm ihtiyaçlarınıza yönelik kapsamlı çözümler sunarak markanızı yükseltiyoruz. iz de markanızı bizimle yenileyerek başarıya giden yolda adımlarınızı sağlamlaştırmak için bizimle iletişime geçebilirsiniz." />
            </Helmet>

            {/* Main Section */}
            <section>
                <div className="relative">
                    <img className="h-[calc(100vh-2.25rem)] min-h-[45rem] object-cover w-full" src={main} alt="Logo" />
                    <div className="absolute top-0 bottom-0 left-0 right-0">
                        <div className="container w-full h-full ">
                            <div className="relative flex items-center justify-center w-full h-full text-white">
                                <SwiperSlider className="z-20 py-[100px]"
                                    modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                                    spaceBetween={50} slideView={1} loop={true}
                                    initialSlide={1} delay={5000} speed={10000} padding={100}
                                    slides={[
                                        <BannerSlide items={['Kurumsal Kimlik', 'Post Tasarım', 'Özel Gün Tasarımı']}
                                            title={'SOSYAL MEDYA TASARIMI'} paragraph={'TSosyal medya tasarımı, markanın dijital varlığını güçlendirmek ve hedef kitleyle etkileşimi artırmak için kritik bir öneme sahiptir. Sosyal medya platformlarında marka bilinirliğini artırmanın anahtarıdır.Profesyonel grafik tasarım ve görsel içerik üretimiyle, markaların sosyal medya platformlarında destekliyor ve etkileşimi artırıyoruz.'}
                                            svg={'specialsocial'} image={'media-tasarim'} alt={'Sosyal Medya Tasarımı'}
                                        />,
                                        <BannerSlide items={['Web Yazılımı', 'Responsive Yazılım', 'Web Yazılımı']}
                                            title={'ÖZEL WEB / MOBİL YAZILIMI'} paragraph={'Time Yazılım olarak, web site tasarımı ve web site yazılımı konularında uzmanlaşmış bir ekibiz. Müşterilerimizin ihtiyaçlarına uygun özelleştirilmiş web çözümleri sunarak, dijital varlıklarını en üst düzeye çıkarmalarına yardımcı oluyoruz. Kullanıcı dostu ve estetik açıdan çekici web siteleri tasarlayarak, markanızın çevrimiçi varlığını güçlendiriyoruz.'}
                                            svg={'specialweb'} image={'web-gelistirme'} alt={'web geliştirme'}
                                        />,
                                        <BannerSlide items={['Web Tasarımı', 'Mobil Tasarım', 'Arayüz Tasarımı']}
                                            title={'WEB/ MOBİL TASARIM'} paragraph={'Estetik bir tasarımın yanı sıra, kullanıcı dostu arayüzler ve kolay erişilebilirlik, başarılı bir web veya mobil uygulamanın vazgeçilmez unsurlarıdır. Time Yazılım olarak, yenilikçi tasarım tekniklerini ve güncel trendleri takip ederek, müşterilerimize etkileyici ve işlevsel web ve mobil arayüzler sunuyoruz'}
                                            svg={'specialdesign'} image={'web-tasarim'} alt={'web tasarım'}
                                        />
                                    ]}
                                />
                                <div className="absolute left-0 z-10 flex items-center lg:-left-20">
                                    <button className="swiper-button-prev"> <ChangeSVG name="special_arrow_right" width={24} height={24} color="#fff" /> </button>
                                </div>
                                <div className="absolute right-0 z-10 flex items-center lg:-right-20">
                                    <button className="swiper-button-next"> <ChangeSVG className="rotate-90" name="special_arrow_left" width={24} height={24} color="#000" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Stand Section */}
            <section>
                <article className="bg-[#0067A1] py-3 text-white">
                    <div className="px-4">
                        <SwiperSlider
                            modules={[Navigation, Scrollbar, Autoplay]}
                            centered={true}
                            centeredBounds={true}
                            spaceBetween={36}
                            slideView={4}
                            loop={true}

                            slides={[
                                <StandSlide title="Sosyal Medya Tasarım" description="Etkili Sosyal Medya Tasarımları Edinin." svg="socialmedia" />,
                                <StandSlide title="Responsive Yazılım" description="Tüm Cihazlara Uyumlu Yazılımlar Edinin." svg="responsive" />,
                                <StandSlide title="Kullanıcı Dostu" description="Tüm Tasarımlarımız Kullanıcı Dostudur." svg="userfriendly" />,
                                <StandSlide title="Güçlü Altyapı Desteği" description="Son Teknoloji Yazılımlarla Destekliyoruz." svg="softwaresupport" />,
                                <StandSlide title="Yaratıcı Tasarımlar" description="Göze Hitap Eden Tasarımlar Üretiyoruz." svg="artdesign" />,
                                <StandSlide title="Uygulama Geliştirme" description="Mobil Tasarım ve Yazılım Geliştiriyoruz." svg="softwarephone" />,
                            ]}
                        />
                    </div>
                </article>
            </section>

            {/* About Section */}
            <section>
                <article className="relative">
                    <ChangeIMG page={'anasayfa'} name={'webanner'} alt={'Web Geliştirme'} height={'section'} />
                    <div className="absolute top-0 bottom-0 end-0 start-0">
                        <div className="container z-10 w-full h-full">
                            <div className="flex flex-col items-center justify-center w-full h-full pb-12 text-white md:flex-row">
                                <div className="order-1 pt-24 md:pt-0 md:order-0 lg:basis-6/12 ">
                                    <h1 className="lg:w-9/12 text-3xl md:text-5xl leading-[4rem]"><span className="font-light">Tasarım ve Yazılım Deneyiminizi</span> <span className="text-[rgb(252,192,9)] font-extrabold italic [text-shadow:_0_0_12px_rgb(252_192_9/_100%)]">Zamanın Ötesine Taşıyoruz!</span></h1>
                                    <p className="mt-10">Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.</p>
                                    <div className="mt-12">
                                        <Link to="/hakkimizda" className="py-3 px-6 rounded-md bg-[#FCC009] text-[#725600] font-semibold">Bizi Yakından Tanıyın </Link>
                                    </div>
                                </div>
                                <div className="lg:basis-6/12 order-0 md:order-1">
                                    <div className="relative">
                                        <ChangeIMG page={'anasayfa'} name={'hakkimizda'} alt={'Web Geliştirme'} />
                                        <div className="absolute -bottom-14 end-0 start-0">
                                            <div className="grid grid-cols-3 gap-4 px-4">
                                                <ChangeIMG rounded={'md'} page={'anasayfa'} name={'hakkimizdaui'} alt={'Web Geliştirme'} />
                                                <ChangeIMG rounded={'md'} page={'anasayfa'} name={'hakkimizdatasarim'} alt={'Web Geliştirme'} />
                                                <ChangeIMG rounded={'md'} page={'anasayfa'} name={'hakkimizdaweb'} alt={'Web Geliştirme'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>

            {/* Service Section */}
            <section className="py-20 bg-white">
                <article className="container">
                    <div className="flex items-center justify-between">
                        <div className="lg:w-8/12">
                            <h3 className="text-3xl md:text-5xl font-rubik">HİZMETLERİMİZ</h3>
                            <p className="mt-4">Time Yazılım, müşterilerine geniş bir yelpazede yazılım çözümleri sunmaktadır. Müşterilerimize ihtiyaçlarına özel çözümler sunarak, verimliliği artırmalarına, maliyetleri düşürmelerine ve rekabet avantajı elde etmelerine yardımcı oluyoruz.</p>
                        </div>
                        <div className="hidden md:block">
                            <div className="text-[#008BC9] border border-[#008BC9] hover:bg-black hover:shadow-sm hover:text-white duration-300 rounded-full py-2 group">
                                <Link className="flex items-center gap-3 px-8 py-2" to='/hizmetlerimiz'>
                                    <span>Tüm Hizmetleri Görüntüle</span>
                                    <div className="duration-300 group-hover:rotate-180">
                                        <ChangeSVG name="arrowoutline" width={24} height={24} color="#008BC9" />
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-6 text-sm font-semibold">
                                Bütün Hizmetlerimiz Hakkında <Link className="text-[#008BC9]" to='/iletisim'>Bize Ulaşın!</Link>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="container mt-12">
                    <div className="grid grid-cols-1 md:grid-cols-3">
                        <ServiceCardList />
                    </div>
                </article>
            </section>

            {/* Projects Section */}
            <section className="py-20 bg-[#F9F9F9]">
                <article className="container">
                    <div className="flex items-center justify-between">
                        <div className="lg:w-8/12">
                            <h3 className="text-3xl md:text-5xl font-rubik">SON PROJELERİMİZ</h3>
                            <p className="mt-4">Her proje, müşterinin ihtiyaçlarına ve hedeflerine özel olarak tasarlanır ve geliştirilir. Kullanıcı deneyimini ön planda tutarak, modern tasarım ve teknolojileri bir araya getiriyoruz.</p>
                        </div>
                        <div className="hidden md:block">
                            <div className="text-[#008BC9] border border-[#008BC9] hover:bg-black hover:shadow-sm hover:text-white duration-300 rounded-full py-2 group">
                                <Link className="flex items-center gap-3 px-8 py-2" to='/projelerimiz'>
                                    <span>Tüm Projeleri Görüntüle</span>
                                    <div className="duration-300 group-hover:rotate-180">
                                        <ChangeSVG name="arrowoutline" width={24} height={24} color="#008BC9" />
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-6 text-sm font-semibold">
                                Bütün Hizmetlerimiz Hakkında <Link className="text-[#008BC9]" to=''>Bize Ulaşın!</Link>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="container pt-16">
                    <BusinessCard />
                    <div className="flex items-center gap-2 mt-12">
                        <ChangeSVG name="arrowoutline" width={24} height={24} color="#1A97CE" />
                        <span className="text-[#1A97CE] font-medium text-lg mb-[2px]">Tüm Projelerimizi Görüntüle</span>
                    </div>
                </article>
            </section>

            {/* Blog Section */}
            <section className="pt-20 bg-white">
                <article className="container">
                    <div className="flex flex-col items-start gap-2 lg:items-end md:flex-row">
                        <h4 className="text-5xl italic font-extrabold">BLOGLARIMIZ</h4>
                        <span className="text-lg font-extrabold">Yazılım & Tasarım Yazılarımız</span>
                    </div>
                    <p className="mt-6 text-sm">Müşterilerimize ve ziyaretçilere, web tasarımı, yazılım geliştirme, SEO stratejileri, dijital pazarlama ve daha birçok konuda bilgi ve ipuçları sağlıyoruz. deneyimlerimizi ve başarı hikayelerimizi paylaşarak, okuyucularımıza ilham kaynağı olmayı amaçlıyoruz. Bloglarımızı düzenli olarak güncelleyerek, son bilgilere ulaşmanızda destek veriyoruz.</p>
                </article>
                <article className="relative px-8 mt-12">
                    <SwiperSlider
                        modules={[Navigation, Scrollbar, Autoplay]}
                        spaceBetween={36}
                        slideView={3}
                        loop={true}

                        slides={[
                            <BlogCard key={1} title={'Projenizi Nasıl Hazırlıyor?'} link={'/blog/medya-tasarimi'}
                                content={'Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.'}
                                image={'blog01'} date={'12 Ocak 2024'}
                            />,
                            <BlogCard key={2} title={'Kurumsal Web Tasarımı Nedir?'} link={'/blog/web-tasarim-onemi'}
                                content={'Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.'}
                                image={'blog02'} date={'18 Ocak 2024'}
                            />,
                            <BlogCard key={2} title={'Web / Mobil Uygulama Yazılımı Nedir?'} link={'/blog/mobil-uygulama-yazilimi'}
                                content={'Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.'}
                                image={'blog03'} date={'24 Ocak 2024'}
                            />,
                            <BlogCard key={3} title={'Özel Web Yazılımı Nedir?'} link={'/blog/web-yazilim-onemi'}
                                content={'Müşterilerimize yenilikçi ve özelleştirilmiş dijital çözümler sunuyoruz. Müşterilerimizin iş ihtiyaçlarını anlamak ve onların hedeflerine ulaşmalarını sağlamak için tutkuyla çalışıyoruz.'}
                                image={'blog04'} date={'02 Şubat 2024'}
                            />

                        ]}
                    />
                    <div className="absolute top-0 bottom-0 left-0 z-10 flex items-center">
                        <button className="swiper-button-prev"> <ChangeSVG name="special_arrow_right" width={24} height={24} color="#fff" /> </button>
                    </div>
                    <div className="absolute top-0 bottom-0 right-0 z-10 flex items-center">
                        <button className="swiper-button-next"> <ChangeSVG className="rotate-90" name="special_arrow_left" width={24} height={24} color="#000" /></button>
                    </div>
                </article>
            </section>

            {/* Contact Section */}
            <section className="py-20 bg-white">
                <article className="container">
                    <ContactCard />
                </article>
            </section>

        </main>
    )
}

export default Home;