const hizmetler = [
    {
        id: 'web-yazilim',
        title: 'ÖZEL WEB YAZILIMI',
        content: 'Time Yazılım olarak, müşterilerimize çağın gereksinimlerine uygun kapsamlı hizmetler sunuyoruz. Web, mobil, sosyal medya ve daha fazlasıyla ilgili geniş bir yelpazede hizmetlerimiz bulunmaktadır. Her biri uzmanlık alanlarımızda deneyimli ekiplerimizle, müşterilerimizin dijital varlıklarını en üst düzeye çıkarmak için çabalıyoruz.',
        image: 'webyazilimbanner',
        background:'hizmetbackground',
        cardSwiper: [
            {
                svg: 'responsive',
                title: 'Responsive Yazılım',
                content: 'Kullanıcı dostu bir şekilde tasarlanmış yazılım çözümlerini ifade eder. Dijital platformlar için responsive yazılım geliştirmek, kullanıcıların her türlü cihazda kolaylıkla erişim sağlayabilmesini sağlar.',
            },
            {
                svg: 'softwaresupport',
                title: 'Clean Code Temiz Kodlama',
                content: 'Clean Code temel prensipleri arasında kod tekrarından kaçınma, küçük ve işlevsel fonksiyonlar yazma, gereksiz yorumlardan kaçınma, tek bir görevi yerine getiren fonksiyonlar oluşturma gibi unsurlar bulunur.',
            },
            {
                svg: 'flash',
                title: 'Hızlıdan Daha Hızlı',
                content: 'Time Yazılım olarak, müşterilerimize çağın gereksinimlerine uygun kapsamlı hizmetler sunuyoruz. Web, mobil, sosyal medya ve daha fazlasıyla ilgili geniş bir yelpazede hizmetlerimiz bulunmaktadır.',
            },
            {
                svg: 'quality',
                title: 'Yönetim Paneli',
                content: 'Yönetim panelleri genellikle belirli işlevselliklere sahip olabilirler. Bunlar arasında kullanıcı yönetimi, içerik yönetimi, veri analitiği, envanter yönetimi, sipariş yönetimi, müşteri ilişkileri yönetimi gibi birçok alan bulunabilir. ',
            },
        ],
        techTitle: 'Özel Web Yazılımı İle İlgili Kullandığımız Teknolojiler',
        techParagraph: 'Sürekli olarak teknoloji trendlerini takip ediyor ve müşterilerimize en iyi hizmeti sunmak için yenilikçi çözümler geliştiriyoruz. Bu doğrultuda, güvenilir altyapılar, kullanıcı dostu arayüzler ve ölçeklenebilir sistemler oluşturarak müşterilerimizin beklentilerini karşılamayı hedefliyoruz.',
        techLogos: [
            { logo: 'bootstrap' }, { logo: 'tailwind' }, { logo: 'javascript' }, { logo: 'php' }, { logo: 'codeigniter' }, { logo: 'nodejs' }, { logo: 'react' }, { logo: 'mysql' },
        ],
        faq: [
            {
                title: 'Özel Web Yazılımı Nedir?',
                content: 'Özel web yazılımı, belirli bir kuruluşun veya işletmenin ihtiyaçlarına özel olarak tasarlanmış ve geliştirilmiş web tabanlı bir yazılım sistemidir. Bu tür yazılımlar genellikle genel amaçlı çözümlerden farklı olarak, belirli iş gereksinimlerini karşılamak için özelleştirilmiş ve esnek bir şekilde tasarlanmıştır. Özel web yazılımları, işletmelerin veya kuruluşların belirli iş süreçlerini yönetmek, verimliliği artırmak, müşteri ilişkilerini yönetmek, veri analizi yapmak, içerik yönetimi sağlamak veya e-ticaret işlemlerini yönetmek gibi çeşitli amaçlarla kullanılabilir. Bu tür yazılımlar, genellikle işletmelerin ihtiyaçlarına göre özelleştirilmiş fonksiyonlar, veritabanları ve kullanıcı arayüzleri içerir.'
            },
            {
                title: 'Web Yazılımı Geliştirme Süreçleri Nelerdir?',
                content: 'Tasarım onaylandıktan sonra, kodlama ve geliştirme sürecine geçilir. Bu aşamada, yazılım mühendisleri, web geliştiricileri ve veritabanı uzmanları, belirlenen gereksinimlere uygun olarak yazılımı kodlarlar. Geliştirme süreci genellikle bir dizi iteratif döngüyle desteklenir, böylece müşteri geri bildirimleri alınabilir ve gerekli ayarlamalar yapılabilir.'
            },
            {
                title: 'Web Yazılım Geliştirme Sürecinde Tasarım Aşamasının Önemi Nedir?',
                content: 'Kullanıcı Deneyimi (UX) ve Kullanılabilirlik: Tasarım aşaması, kullanıcıların web sitesini veya uygulamayı nasıl kullanacaklarını belirler. Kullanıcı dostu bir arayüz ve kullanılabilirlik, kullanıcıların yazılımı etkili bir şekilde kullanmalarını sağlar ve memnuniyetlerini artırır. Görünüm ve Estetik: Tasarım, yazılımın görsel olarak nasıl görüneceğini belirler. İyi bir tasarım, kullanıcıların dikkatini çeker, markanın imajını yansıtır ve kullanıcıların web sitesine veya uygulamaya olan güvenini artırır.'
            },
            {
                title: 'Bakım Ve Destek Nasıl Yapılır?',
                content: 'Sürekli bakım ve destek, bir yazılımın canlı ortama dağıtılmasının ardından sağlanan önemli bir hizmettir. Bu süreç, yazılımın performansının ve güvenilirliğinin sürdürülmesini sağlamak için gereklidir. Sürekli bakım ve destek genellikle şu faaliyetleri içerir: Hata Düzeltme ve Sorun Giderme, Performans iyileştirmeleri Sürekli bakım ve destek, yazılımın canlı ortamda başarılı bir şekilde çalışmasını sağlamak için önemlidir ve yazılımın ömrü boyunca devam etmelidir. Bu süreç, yazılımın sahip olduğu değeri korur ve kullanıcıların memnuniyetini sağlar.'
            },
        ]
    },
    {
        id: 'web-tasarim',
        title: 'KURUMSAL WEB TASARIMI',
        content: 'Estetik bir tasarımın yanı sıra, kullanıcı dostu arayüzler ve kolay erişilebilirlik, başarılı bir web veya mobil uygulamanın vazgeçilmez unsurlarıdır. Time Yazılım olarak, yenilikçi tasarım tekniklerini ve güncel trendleri takip ederek, müşterilerimize etkileyici ve işlevsel web ve mobil arayüzler sunuyoruz.',
        image: 'webtasarimbanner',
        background:'tasarimbackground',
        cardSwiper: [
            {
                svg: 'penart',
                title: 'Göz Alıcı Tasarım',
                content: 'Göz alıcı tasarım, dijital varlıklarınızı unutulmaz kılar. Tasarımımız, estetik ve işlevsellik arasında mükemmel bir denge kurar, böylece kullanıcılarınızı etkileyici bir deneyimle karşılar. Markanızın kişiliğini yansıtan görsel unsurlar sunuyoruz.',
            },
            {
                svg: 'specialsocial',
                title: 'Her Sektöre Özel',
                content: 'Her sektör benzersizdir ve bu yüzden her biri için özel bir yaklaşım gerektirir. İşletmenizin ihtiyaçlarını ve hedeflerini anlamak için sektörünüzü detaylı bir şekilde inceliyoruz ve buna göre özel tasarım stratejileri geliştiriyoruz. Trendlere uygun çözümler sunuyoruz. ',
            },
            {
                svg: 'flash',
                title: 'Hızlıdan Daha Hızlı',
                content: 'İş dünyasında hız çok önemlidir ve biz bu gereksinimi karşılamak için buradayız. Projenizin zamanında ve öngörülen bütçe dahilinde tamamlanmasını sağlamak için hızlı ve etkili bir şekilde çalışıyoruz. Hızlıdan daha hızlı hizmetimizle başarınızı artırıyoruz.',
            },
            {
                svg: 'quality',
                title: 'Ultra Estetik',
                content: 'Ultra estetik tasarım anlayışımızla, web siteniz veya uygulamanız izleyicileri büyüleyecek. Her detayı özenle tasarlayarak, markanızın özgünlüğünü ve çekiciliğini vurguluyoruz. Ultra estetik tasarımlarımızla  beklentilerini aşmayı hedefliyoruz.',
            },
        ],
        techTitle: 'Kurumsal Web Design İlgili Kullandığımız Teknolojiler',
        techParagraph: 'Sürekli olarak teknoloji trendlerini takip ediyor ve müşterilerimize en iyi hizmeti sunmak için yenilikçi çözümler geliştiriyoruz. Bu doğrultuda, güvenilir altyapılar, kullanıcı dostu arayüzler ve ölçeklenebilir sistemler oluşturarak müşterilerimizin beklentilerini karşılamayı hedefliyoruz.',
        techLogos: [
            { logo: 'photoshop' }, { logo: 'illustrator' }, { logo: 'figma' }, { logo: 'canva' }, { logo: 'aftereffect' }, { logo: 'premierpro' }, { logo: 'adobexd' }, { logo: 'indesign' },
        ],
        faq: [
            {
                title: 'Kurumsal Web Tasarımı Nedir?',
                content: 'Kurumsal web tasarımı, bir şirketin veya kuruluşun dijital varlığını temsil eden profesyonel bir web sitesinin tasarım sürecidir. Kurumsal web siteleri genellikle şirketin marka kimliğini yansıtan, kurumsal renkler ve logolarla uyumlu olarak tasarlanır. Bu tür web siteleri, şirketin ürün ve hizmetlerini tanıtmak, potansiyel müşterilere ulaşmak, mevcut müşterileri bilgilendirmek ve kurumsal imajı güçlendirmek için kullanılır.'
            },
            {
                title: 'Kurumsal Web Tasarımı Süreçleri Nelerdir?',
                content: 'Kurumsal web tasarımı süreci, başlangıçta müşterinin ihtiyaçlarını ve hedeflerini belirleyen bir ihtiyaç analiziyle başlar. Ardından, stratejik bir planlama ve kavram geliştirme aşamasında, web sitesinin ana hatları belirlenir ve site haritası oluşturulur. Sonrasında, web sitesi test edilir ve kalite kontrolü yapılır. Yayınlama ve canlıya alma aşamasında, web sitesi hosting sağlayıcıya yüklenir ve yayına alınır. Son olarak, web sitesi yayına alındıktan sonra sürekli destek ve bakım sağlanarak güncel tutulur.'
            },
            {
                title: 'Tasarım Aşamasının Önemi Nedir?',
                content: 'Tasarım aşaması, kurumsal web sitesinin kullanıcı deneyimini ve marka imajını belirleyen kritik bir süreçtir. Doğru tasarlanmış bir web sitesi, kullanıcıların siteyi ziyaret etme ve etkileşimde bulunma olasılığını artırır. Ayrıca, estetik açıdan çekici bir tasarım, markanın güvenilirliğini ve profesyonelliğini yansıtarak potansiyel müşteriler üzerinde olumlu bir izlenim bırakır. Tasarım aşamasında dikkatli bir planlama ve strateji izlenmesi, kullanıcıların kolaylıkla gezinmesini sağlar ve istenilen bilgilere hızlıca ulaşmalarını sağlar.'
            },
            {
                title: 'Bakım Ve Destek Nasıl Yapılır?',
                content: 'Bakım ve destek, bir kurumsal web sitesinin uzun vadeli başarısını sağlamak için önemli bir unsurdur. Bu süreç, sitenin güncel kalmasını, performansının optimize edilmesini ve güvenliğinin sağlanmasını içerir. Tüm bu süreçler, bir kurumsal web sitesinin uzun vadeli başarısını sağlamak ve kullanıcıların memnuniyetini artırmak için düzenli olarak uygulanmalıdır.'
            },
        ]
    },
    {
        id: 'seo',
        title: 'SEO OPTİMİZASYONU',
        content: 'SEO optimizasyonu hizmeti, web sitelerinin arama motorlarında daha üst sıralarda yer almasını sağlayarak online görünürlüğünü artırmayı hedefler. Profesyonel SEO uzmanlarımız, müşterilerin web sitelerini anahtar kelime araştırması, teknik optimizasyon, içerik oluşturma ve bağlantı stratejileri gibi SEO en iyi uygulamalarına uygun olarak optimize eder.',
        image: 'webseobanner',
        background:'seobackground',
        cardSwiper: [
            {
                svg: 'softwaresupport',
                title: 'Clean Code Temiz Kodlama',
                content: 'Clean Code temel prensipleri arasında kod tekrarından kaçınma, küçük ve işlevsel fonksiyonlar yazma, gereksiz yorumlardan kaçınma, tek bir görevi yerine getiren fonksiyonlar oluşturma gibi unsurlar bulunur. Benzersiz ilerleyişimizi seo konusunda hissedin.',
            },
            {
                svg: 'specialsocial',
                title: 'Her Sektöre Özel',
                content: 'Her sektör benzersizdir ve bu yüzden her biri için özel bir yaklaşım gerektirir. İşletmenizin ihtiyaçlarını ve hedeflerini anlamak için sektörünüzü detaylı bir şekilde inceliyoruz ve buna göre özel tasarım stratejileri geliştiriyoruz. Trendlere uygun çözümler sunuyoruz. ',
            },
            {
                svg: 'flash',
                title: 'Hızlıdan Daha Hızlı',
                content: 'İş dünyasında hız çok önemlidir ve biz bu gereksinimi karşılamak için buradayız. Projenizin zamanında ve öngörülen bütçe dahilinde tamamlanmasını sağlamak için hızlı ve etkili bir şekilde çalışıyoruz. Hızlıdan daha hızlı hizmetimizle başarınızı artırıyoruz.',
            },
            {
                svg: 'quality',
                title: 'Ultra Optimizasyon',
                content: 'Ultra optimizasyon anlayışımızla, web siteniz veya uygulamanız izleyicileri büyüleyecek. Her detayı özenle tarayarak ve inceleyerek, markanızın özgünlüğünü ve çekiciliğini vurguluyoruz. Ultra opimizasyon ile kitle çekme beklentilerine ulaşmayı hedefliyoruz.',
            },
        ],
        techTitle: 'SEO Optimizasyonu İle İlgili Kullandığımız Teknolojiler',
        techParagraph: 'Sürekli olarak teknoloji trendlerini takip ediyor ve müşterilerimize en iyi hizmeti sunmak için yenilikçi çözümler geliştiriyoruz. Bu doğrultuda, güvenilir altyapılar, kullanıcı dostu arayüzler ve ölçeklenebilir sistemler oluşturarak müşterilerimizin beklentilerini karşılamayı hedefliyoruz.',
        techLogos: [
            { logo: 'bootstrap' }, { logo: 'tailwind' }, { logo: 'javascript' }, { logo: 'php' }, { logo: 'codeigniter' }, { logo: 'nodejs' }, { logo: 'react' }, { logo: 'mysql' },
        ],
        faq: [
            {
                title: 'SEO Nedir?',
                content: 'SEO (Search Engine Optimization), bir web sitesinin arama motorlarında organik olarak daha üst sıralarda yer almasını sağlamak için yapılan bir dizi teknik ve stratejik çalışmayı ifade eder. Temel olarak, SEOnun amacı, web sitesinin hedeflenen anahtar kelimelerle ilgili arama sonuçlarında daha görünür olmasını sağlayarak, daha fazla trafik çekmesini ve dolayısıyla daha fazla potansiyel müşteri veya ziyaretçi elde etmesini sağlamaktır.'
            },
            {
                title: 'SEO Optimizasyon Süreçleri Nelerdir?',
                content: 'Web sitelerinin arama motorlarında daha üst sıralarda görünmesini sağlayan bir dizi strateji ve tekniktir. Bu süreç, sitenin organik arama sonuçlarında daha görünür olmasını sağlayarak trafik ve erişilebilirliği artırmayı amaçlar. SEO optimizasyon süreçleri, web sitelerinin arama motorlarında daha üst sıralarda görünmesini sağlayarak organik trafik ve erişilebilirliği artırır. Bu süreçlerin düzenli olarak güncellenmesi ve optimize edilmesi, sitenin uzun vadeli başarısını sağlar.'
            },
            {
                title: 'SEO Önemi Nedir?',
                content: 'Organik Trafik Artışı: SEO nun doğası gereği, organik arama sonuçlarından gelen trafik, reklam gibi diğer yöntemlere göre daha kaliteli ve sürekli olabilir. Bu, web sitesinin uzun vadeli başarısı için önemlidir. Marka Bilinirliği ve Otorite: Arama motorlarında daha üst sıralarda yer almak, bir markanın bilinirliğini artırır ve sektörde otorite olarak kabul edilmesine yardımcı olur. Bu da güvenilirlik ve müşteri sadakati açısından önemlidir.'
            },
            {
                title: 'SEO Bakımı Nasıl Yapılır?',
                content: 'SEO bakımı, web sitesinin arama motorlarında güçlü performansını sürdürmek ve sürekli olarak iyileştirmek için yapılan düzenli işlemleri içerir. çerik Güncellemeleri: Web sitenizin içeriğini düzenli olarak güncellemek ve yenilemek, arama motorlarına sürekli olarak güncel ve değerli bir kaynak sunduğunuzu gösterir. Teknik SEO Kontrolü: Web sitesinin teknik altyapısını düzenli olarak kontrol etmek ve optimize etmek önemlidir.'
            },
        ]
    },
    {
        id: 'mobil-uygulama-tasarimi',
        title: 'MOBİL UYGULAMA TASARIMI',
        content: 'Time Yazılımın mobil uygulama tasarımı hizmeti, kullanıcı odaklı ve görsel olarak etkileyici mobil uygulamaların tasarımını sağlar. Deneyimli tasarım ekibimiz, kullanıcı arayüzü (UI) ve kullanıcı deneyimi (UX) tasarımında en son trendleri ve en iyi uygulamaları takip ederek müşterilere benzersiz ve işlevsel uygulama tasarımları sunar.',
        image: 'mobiltasarimbanner',
        background:'mobiltasarimbackground',
        cardSwiper: [
            {
                svg: 'penart',
                title: 'Göz Alıcı Tasarım',
                content: 'Göz alıcı tasarım, dijital varlıklarınızı unutulmaz kılar. Tasarımımız, estetik ve işlevsellik arasında mükemmel bir denge kurar, böylece kullanıcılarınızı etkileyici bir deneyimle karşılar. Markanızın kişiliğini yansıtan görsel unsurlar sunuyoruz.',
            },
            {
                svg: 'specialsocial',
                title: 'Her Sektöre Özel',
                content: 'Her sektör benzersizdir ve bu yüzden her biri için özel bir yaklaşım gerektirir. İşletmenizin ihtiyaçlarını ve hedeflerini anlamak için sektörünüzü detaylı bir şekilde inceliyoruz ve buna göre özel tasarım stratejileri geliştiriyoruz. Trendlere uygun çözümler sunuyoruz. ',
            },
            {
                svg: 'flash',
                title: 'Hızlıdan Daha Hızlı',
                content: 'İş dünyasında hız çok önemlidir ve biz bu gereksinimi karşılamak için buradayız. Projenizin zamanında ve öngörülen bütçe dahilinde tamamlanmasını sağlamak için hızlı ve etkili bir şekilde çalışıyoruz. Hızlıdan daha hızlı hizmetimizle başarınızı artırıyoruz.',
            },
            {
                svg: 'quality',
                title: 'Ultra Estetik',
                content: 'Ultra estetik tasarım anlayışımızla, web siteniz veya uygulamanız izleyicileri büyüleyecek. Her detayı özenle tasarlayarak, markanızın özgünlüğünü ve çekiciliğini vurguluyoruz. Ultra estetik tasarımlarımızla  beklentilerini aşmayı hedefliyoruz.',
            },
        ],
        techTitle: 'Mobil Uygulama Tasarımı İle İlgili Kullandığımız Teknolojiler',
        techParagraph: 'Sürekli olarak teknoloji trendlerini takip ediyor ve müşterilerimize en iyi hizmeti sunmak için yenilikçi çözümler geliştiriyoruz. Bu doğrultuda, güvenilir altyapılar, kullanıcı dostu arayüzler ve ölçeklenebilir sistemler oluşturarak müşterilerimizin beklentilerini karşılamayı hedefliyoruz.',
        techLogos: [
            { logo: 'photoshop' }, { logo: 'illustrator' }, { logo: 'figma' }, { logo: 'canva' }, { logo: 'aftereffect' }, { logo: 'premierpro' }, { logo: 'adobexd' }, { logo: 'indesign' },
        ],
        faq: [
            {
                title: 'Mobile Uygulama Nedir?',
                content: 'Mobil uygulama, bir akıllı telefon, tablet veya diğer taşınabilir cihazlar için özel olarak geliştirilen yazılım uygulamalarıdır. Bu uygulamalar genellikle iOS (Apple) veya Android (Google) gibi mobil işletim sistemleri için tasarlanır ve kullanıcıların belirli bir amaca veya işlevselliğe sahip olmalarını sağlar. Mobil uygulamalar, bir dizi farklı ihtiyaca yanıt vermek için geliştirilebilir. Örneğin, oyunlar, sosyal medya platformları, e-ticaret uygulamaları, haber okuyucuları, finansal araçlar, sağlık ve fitness uygulamaları, eğitim araçları ve daha pek çok farklı türde mobil uygulama bulunmaktadır.'
            },
            {
                title: 'Mobil Uygulama Geliştirme Süreçleri Nelerdir?',
                content: 'İhtiyaç Analizi ve Planlama,  Tasarım ve Prototipleme, Geliştirme, Test Etme ve Kalite Güvencesi, Yayınlama ve Dağıtım, Güncelleme ve Bakım  Uygulamanın yayınlanmasının ardından, sürekli olarak güncelleme ve bakım yapılması gerekir. Kullanıcı geri bildirimleri ve performans izleme verileri, uygulamanın iyileştirilmesi ve güncellenmesi için değerli geri bildirimler sağlar. Ayrıca, yeni işlevselliklerin eklenmesi ve güvenlik güncellemelerinin yapılması da önemlidir. Bu süreç, mobil uygulamanın başarılı bir şekilde geliştirilmesi için izlenmesi gereken temel adımları içerir. Her aşama, dikkatli planlama, işbirliği ve kalite kontrolü gerektirir.'
            },
            {
                title: 'Mobil Uygulama Geliştirme Sürecinde Tasarım Aşamasının Önemi Nedir?',
                content: 'İyi bir tasarım, kullanıcı deneyimini etkileyerek kullanıcıların uygulamayı tercih etme ve tekrar kullanma olasılığını artırır. Kullanıcı dostu bir arayüz ve kolay kullanılabilirlik, uygulamanın başarısını belirleyen faktörlerden biridir. Tasarım, uygulamanın marka kimliğini yansıtır ve kullanıcıların marka hakkında olumlu bir algı oluşturmasına yardımcı olur. Renkler, fontlar, logolar ve diğer grafik öğeler, markanın kişiliğini ve tarzını yansıtmak için önemlidir. Tasarım aşaması, mobil uygulamanın başarılı bir şekilde kullanıcılar tarafından benimsenmesi ve işletme hedeflerine ulaşması için kritik bir öneme sahiptir. Bu nedenle, tasarım sürecine yeterli zaman, kaynak ve dikkat ayrılması önemlidir.'
            },
            {
                title: 'Tasarım Desteği Nasıl Verilir?',
                content: 'Kullanıcı ihtiyaçları ve beklentileri doğrultusunda bir tasarım stratejisi belirlenir. Kullanıcı araştırmaları yapılır, kullanıcıların davranışları ve tercihleri analiz edilir. Buna göre uygulamanın kullanıcı dostu bir arayüze sahip olması sağlanır. Uygulamanın görsel tasarımı, marka kimliğiyle uyumlu olacak şekilde oluşturulur. Renkler, fontlar, grafikler ve diğer görsel öğeler markanın kişiliğini yansıtacak şekilde seçilir. Tasarım desteği, mobil uygulamanın kullanıcıların beklentilerini karşılamasını ve rekabet avantajı elde etmesini sağlar. Bu nedenle, tasarım sürecine yeterli önem ve kaynak ayrılması önemlidir.'
            },
        ]
    },
    {
        id: 'mobil-uygulama-yazilimi',
        title: 'MOBİL UYGULAMA YAZILIMI',
        content: 'Time Yazılımın mobil uygulama yazılımı hizmeti, müşterilerin mobil platformlarda etkili ve kullanıcı dostu uygulamalar geliştirmesine olanak tanır. Profesyonel mobil uygulama geliştirme ekibimiz, müşteri ihtiyaçlarına ve hedeflerine uygun özelleştirilmiş çözümler sunar. Uygulama geliştirme yeteneklerine sahibiz.',
        image: 'mobilyazilimbanner',
        background:'mobilyazilimbackground',
        cardSwiper: [
            {
                svg: 'softwaresupport',
                title: 'Clean Code Temiz Kodlama',
                content: 'Clean Code temel prensipleri arasında kod tekrarından kaçınma, küçük ve işlevsel fonksiyonlar yazma, gereksiz yorumlardan kaçınma, tek bir görevi yerine getiren fonksiyonlar oluşturma gibi unsurlar bulunur. Benzersiz ilerleyişimizi seo konusunda hissedin.',
            },
            {
                svg: 'specialsocial',
                title: 'Her Sektöre Özel',
                content: 'Her sektör benzersizdir ve bu yüzden her biri için özel bir yaklaşım gerektirir. İşletmenizin ihtiyaçlarını ve hedeflerini anlamak için sektörünüzü detaylı bir şekilde inceliyoruz ve buna göre özel tasarım stratejileri geliştiriyoruz. Trendlere uygun çözümler sunuyoruz. ',
            },
            {
                svg: 'flash',
                title: 'Hızlıdan Daha Hızlı',
                content: 'İş dünyasında hız çok önemlidir ve biz bu gereksinimi karşılamak için buradayız. Projenizin zamanında ve öngörülen bütçe dahilinde tamamlanmasını sağlamak için hızlı ve etkili bir şekilde çalışıyoruz. Hızlıdan daha hızlı hizmetimizle başarınızı artırıyoruz.',
            },
            {
                svg: 'quality',
                title: 'Ultra Optimizasyon',
                content: 'Ultra optimizasyon anlayışımızla, web siteniz veya uygulamanız izleyicileri büyüleyecek. Her detayı özenle tarayarak ve inceleyerek, markanızın özgünlüğünü ve çekiciliğini vurguluyoruz. Ultra opimizasyon ile kitle çekme beklentilerine ulaşmayı hedefliyoruz.',
            },
        ],
        techTitle: 'Mobil Uygulama Yazılımı İle İlgili Kullandığımız Teknolojiler',
        techParagraph: 'Sürekli olarak teknoloji trendlerini takip ediyor ve müşterilerimize en iyi hizmeti sunmak için yenilikçi çözümler geliştiriyoruz. Bu doğrultuda, güvenilir altyapılar, kullanıcı dostu arayüzler ve ölçeklenebilir sistemler oluşturarak müşterilerimizin beklentilerini karşılamayı hedefliyoruz.',
        techLogos: [
            { logo: 'kotlin' }, { logo: 'native' }, { logo: 'flutter' }, { logo: 'dart' }, { logo: 'mysql' }, { logo: 'nodejs' }, 
        ],
        faq: [
            {
                title: 'Mobile Uygulama Nedir?',
                content: 'Mobil uygulama, bir akıllı telefon, tablet veya diğer taşınabilir cihazlar için özel olarak geliştirilen yazılım uygulamalarıdır. Bu uygulamalar genellikle iOS (Apple) veya Android (Google) gibi mobil işletim sistemleri için tasarlanır ve kullanıcıların belirli bir amaca veya işlevselliğe sahip olmalarını sağlar. Mobil uygulamalar, bir dizi farklı ihtiyaca yanıt vermek için geliştirilebilir. Örneğin, oyunlar, sosyal medya platformları, e-ticaret uygulamaları, haber okuyucuları, finansal araçlar, sağlık ve fitness uygulamaları, eğitim araçları ve daha pek çok farklı türde mobil uygulama bulunmaktadır.'
            },
            {
                title: 'Mobil Uygulama Geliştirme Süreçleri Nelerdir?',
                content: 'İhtiyaç Analizi ve Planlama,  Tasarım ve Prototipleme, Geliştirme, Test Etme ve Kalite Güvencesi, Yayınlama ve Dağıtım, Güncelleme ve Bakım  Uygulamanın yayınlanmasının ardından, sürekli olarak güncelleme ve bakım yapılması gerekir. Kullanıcı geri bildirimleri ve performans izleme verileri, uygulamanın iyileştirilmesi ve güncellenmesi için değerli geri bildirimler sağlar. Ayrıca, yeni işlevselliklerin eklenmesi ve güvenlik güncellemelerinin yapılması da önemlidir. Bu süreç, mobil uygulamanın başarılı bir şekilde geliştirilmesi için izlenmesi gereken temel adımları içerir. Her aşama, dikkatli planlama, işbirliği ve kalite kontrolü gerektirir.'
            },
            {
                title: 'Mobil Uygulama Geliştirme Sürecinde Tasarım Aşamasının Önemi Nedir?',
                content: 'İyi bir tasarım, kullanıcı deneyimini etkileyerek kullanıcıların uygulamayı tercih etme ve tekrar kullanma olasılığını artırır. Kullanıcı dostu bir arayüz ve kolay kullanılabilirlik, uygulamanın başarısını belirleyen faktörlerden biridir. Tasarım, uygulamanın marka kimliğini yansıtır ve kullanıcıların marka hakkında olumlu bir algı oluşturmasına yardımcı olur. Renkler, fontlar, logolar ve diğer grafik öğeler, markanın kişiliğini ve tarzını yansıtmak için önemlidir. Tasarım aşaması, mobil uygulamanın başarılı bir şekilde kullanıcılar tarafından benimsenmesi ve işletme hedeflerine ulaşması için kritik bir öneme sahiptir. Bu nedenle, tasarım sürecine yeterli zaman, kaynak ve dikkat ayrılması önemlidir.'
            },
            {
                title: 'Tasarım Desteği Nasıl Verilir?',
                content: 'Kullanıcı ihtiyaçları ve beklentileri doğrultusunda bir tasarım stratejisi belirlenir. Kullanıcı araştırmaları yapılır, kullanıcıların davranışları ve tercihleri analiz edilir. Buna göre uygulamanın kullanıcı dostu bir arayüze sahip olması sağlanır. Uygulamanın görsel tasarımı, marka kimliğiyle uyumlu olacak şekilde oluşturulur. Renkler, fontlar, grafikler ve diğer görsel öğeler markanın kişiliğini yansıtacak şekilde seçilir. Tasarım desteği, mobil uygulamanın kullanıcıların beklentilerini karşılamasını ve rekabet avantajı elde etmesini sağlar. Bu nedenle, tasarım sürecine yeterli önem ve kaynak ayrılması önemlidir.'
            },
        ]
    },
    {
        id: 'sosyal-medya-tasarimi',
        title: 'SOSYAL MEDYA TASARIMI',
        content: 'Time Yazılımın sosyal medya tasarımı hizmeti, markaların dijital varlıklarını güçlendirmek ve etkileşimlerini artırmak için özelleştirilmiş çözümler sunar. Deneyimli tasarım ekibimiz, markaların hedef kitlesine ulaşmasını sağlayacak görsel olarak çekici ve etkileyici içerikler oluşturur. Son trendleri ve en iyi uygulamaları takip ederek güçlendiriyoruz.',
        image: 'medyabanner',
        background:'medyabackground',
        cardSwiper: [
            {
                svg: 'penart',
                title: 'Göz Alıcı Tasarım',
                content: 'Göz alıcı tasarım, dijital varlıklarınızı unutulmaz kılar. Tasarımımız, estetik ve işlevsellik arasında mükemmel bir denge kurar, böylece kullanıcılarınızı etkileyici bir deneyimle karşılar. Markanızın kişiliğini yansıtan görsel unsurlar sunuyoruz.',
            },
            {
                svg: 'specialsocial',
                title: 'Her Sektöre Özel',
                content: 'Her sektör benzersizdir ve bu yüzden her biri için özel bir yaklaşım gerektirir. İşletmenizin ihtiyaçlarını ve hedeflerini anlamak için sektörünüzü detaylı bir şekilde inceliyoruz ve buna göre özel tasarım stratejileri geliştiriyoruz. Trendlere uygun çözümler sunuyoruz. ',
            },
            {
                svg: 'flash',
                title: 'Hızlıdan Daha Hızlı',
                content: 'İş dünyasında hız çok önemlidir ve biz bu gereksinimi karşılamak için buradayız. Projenizin zamanında ve öngörülen bütçe dahilinde tamamlanmasını sağlamak için hızlı ve etkili bir şekilde çalışıyoruz. Hızlıdan daha hızlı hizmetimizle başarınızı artırıyoruz.',
            },
            {
                svg: 'quality',
                title: 'Ultra Estetik',
                content: 'Ultra estetik tasarım anlayışımızla, web siteniz veya uygulamanız izleyicileri büyüleyecek. Her detayı özenle tasarlayarak, markanızın özgünlüğünü ve çekiciliğini vurguluyoruz. Ultra estetik tasarımlarımızla  beklentilerini aşmayı hedefliyoruz.',
            },
        ],
        techTitle: 'Sosyal Medya Post Tasarımı İle İlgili Kullandığımız Teknolojiler',
        techParagraph: 'Sürekli olarak teknoloji trendlerini takip ediyor ve müşterilerimize en iyi hizmeti sunmak için yenilikçi çözümler geliştiriyoruz. Bu doğrultuda, güvenilir altyapılar, kullanıcı dostu arayüzler ve ölçeklenebilir sistemler oluşturarak müşterilerimizin beklentilerini karşılamayı hedefliyoruz.',
        techLogos: [
            { logo: 'photoshop' }, { logo: 'illustrator' }, { logo: 'figma' }, { logo: 'canva' }, { logo: 'aftereffect' }, { logo: 'premierpro' }, { logo: 'adobexd' }, { logo: 'indesign' },
        ],
        faq: [
            {
                title: 'Sosyal Medya Nedir?',
                content: 'Sosyal medya, internet üzerinden insanların etkileşimde bulunabileceği ve içerik paylaşabileceği çeşitli platformlardan oluşan bir ağdır. Kullanıcılar, metin, resim, video veya ses gibi çeşitli medya türlerini paylaşabilir ve diğer kullanıcılarla etkileşim kurabilirler. Sosyal medya platformları genellikle kişisel veya profesyonel amaçlar için kullanılır ve geniş bir kullanıcı tabanına sahiptir.'
            },
            {
                title: 'Sosyal Medyada Ne Gibi Tasarımlar Yapılır?',
                content: 'Özel kampanyalar veya indirimler için özel görseller tasarlanabilir. Bu görseller, dikkat çekici renkler, ilginç grafikler ve net bir mesaj içerebilir. osyal medyada görsel içerikler çok önemlidir. Görsel içerikler, ilgi çekici grafikler, infografikler, resimler veya videolar içerebilir. Bu içerikler, ürünleri veya hizmetleri tanıtmak, bilgi vermek veya kullanıcıları eğlendirmek amacıyla paylaşılabilir. Bu tasarımlar, markanın kimliğini yansıtmak, kullanıcıları etkilemek ve marka bilinirliğini artırmak için önemlidir.'
            },
            {
                title: 'Sosyal Medya Tasarımının Önemi Nedir?',
                content: 'Marka Kimliği Yansıtma: Sosyal medya tasarımı, markanın değerlerini, kişiliğini ve hedeflerini yansıtan görsel unsurları içerir. Logolar, renkler, yazı tipleri ve grafikler gibi öğeler, markanın benzersiz kimliğini tanımlar ve kullanıcılara marka hakkında bilgi verir. Etkileşimi Artırma: Kullanıcıların sosyal medya içeriğine etkileşimde bulunmalarını teşvik etmek için etkileyici tasarımlar kullanılabilir. Örneğin, ilgi çekici görseller, etkileşimli infografikler veya çekici videolar, kullanıcıların paylaşma, beğenme veya yorum yapma olasılığını artırabilir.'
            },
            {
                title: 'Ne Gibi Hizmetler Vardır?',
                content: 'Sosyal medya tasarımı, markaların ve işletmelerin çevrimiçi varlıklarını güçlendirmek ve hedef kitleleriyle etkileşimi artırmak için önemli bir unsurdur. Bu kapsamda sunulan hizmetler genellikle şunları içerir: Profil ve kapak fotoğraflarının tasarlanması, gönderi ve reklam grafiklerinin oluşturulması, etkileşimli içeriklerin hazırlanması (örneğin, anketler, yarışmalar), hikaye ve canlı yayın görsellerinin hazırlanması, marka kimliğine uygun grafiklerin oluşturulması ve sosyal medya hesaplarının görsel olarak uyumlu bir şekilde yönetilmesi.'
            },
        ]
    },

];

export default hizmetler;
