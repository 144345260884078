import { Link, useParams } from 'react-router-dom';
import ChangeIMG from '../helpers/ChangeIMG';
import ChangeSVG from '../helpers/ChangeSVG';
import SwiperSlider from '../components/SwiperSlider';
import { Navigation, Scrollbar, Autoplay } from 'swiper/modules';
import AccordionContainer from '../components/AccordionList';
import BusinessCardList from '../components/BusinessCard';
import hizmetler from '../data/Hizmetler';
import { Helmet } from 'react-helmet';



const HizmetDetay = () => {

    const { id } = useParams();

    const hizmet = hizmetler.find((item) => item.id === id);

    if (!hizmet) {
        return <div>Böyle bir hizmet bulunamadı.</div>;
    }


    return (
        <main>

            <Helmet>
                <link rel="canonical" href={`https://timeyazilim.com.tr/${id}`} />
                <title>{hizmet.title}</title>
                <meta name="description" content={`${hizmet.content}`} />
            </Helmet>

            <section className="relative">
                <ChangeIMG page='hizmetlerimiz' name={hizmet.background} alt={hizmet.title} height={'hizmetdetay'} />
                <div className="absolute top-0 bottom-0 left-0 right-0">
                    <article className="container">
                        <div className="flex flex-col items-center mt-12 text-white md:mt-24 md:flex-row gap-9">
                            <div className="basis-1/2">
                                <ChangeIMG page='hizmetlerimiz' name={hizmet.image} alt='hizmet' />
                            </div>
                            <div className="basis-1/2">
                                <span className="text-[#00B1FF] font-bold italic">Zamanın Ötesine!</span>
                                <h1 className="mt-4 text-3xl font-rubik">{hizmet.title}</h1>
                                <p className="text-sm opacity-50 mt-9">{hizmet.content}</p>
                                <div className="flex items-center gap-3 mt-9">
                                    <ChangeSVG name={'dot'} height={26} width={26} color={''} />
                                    <span className="text-sm font-semibold">Her Sektörün İhtiyaçlarına Özel Çözümler Sunuyoruz.</span>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className='container relative pt-16 text-white'>
                        <SwiperSlider
                            modules={[Navigation, Scrollbar, Autoplay]}
                            spaceBetween={36}
                            slideView={3}
                            loop={true}
                            MobileView={1}

                            slides={hizmet.cardSwiper.map((item, index) => (
                                <div key={index} className='px-5 bg-white border border-white border-opacity-25 rounded-md bg-opacity-10 py-9 backdrop-blur-xl'>
                                    <ChangeSVG name={item.svg} height={64} width={64} color={'#fff'} />
                                    <span className='flex pt-4'>{item.title}</span>
                                    <p className='flex text-xs font-normal opacity-50 pt-7'>{item.content}</p>
                                </div>
                            ))}
                        />
                        <div className="absolute bottom-0 left-0 z-10 flex items-center top-7 md:-left-20">
                            <button className="swiper-button-prev"> <ChangeSVG name="special_arrow_right" width={24} height={24} color="#fff" /> </button>
                        </div>
                        <div className="absolute bottom-0 right-0 z-10 flex items-center top-7 md:-right-20">
                            <button className="swiper-button-next"> <ChangeSVG className="rotate-90" name="special_arrow_left" width={24} height={24} color="#000" /></button>
                        </div>
                    </article>
                </div>

            </section>

            <section className='py-20'>
                <article className='container text-center'>
                    <h2 className='text-3xl font-extrabold'>{hizmet.techTitle}</h2>
                    <p className='mt-6 mb-14'>{hizmet.techParagraph}</p>
                </article>
                <article className='container'>

                    <div className="grid grid-cols-2 gap-5 lg:grid-cols-4">
                        {hizmet.techLogos.map((logo, index) => (
                            <div key={index} className="flex items-center justify-center bg-white rounded-md techLogo">
                                <div className='w-40 py-4'>
                                    <ChangeIMG page={'logo'} name={logo.logo} alt={logo.logo} />
                                </div>
                            </div>
                        ))}
                    </div>
                </article>
            </section>

            <section className='py-20 bg-white'>
                <AccordionContainer faq={hizmet.faq} />
            </section>

            <section className="py-20 bg-[#F9F9F9]">
                <article className="container">
                    <div className="flex flex-col items-center justify-between md:flex-row">
                        <div className="lg:w-8/12">
                            <h3 className="text-3xl lg:text-5xl font-rubik">SON PROJELERİMİZ</h3>
                            <p className="mt-4">Her proje, müşterinin ihtiyaçlarına ve hedeflerine özel olarak tasarlanır ve geliştirilir. Kullanıcı deneyimini ön planda tutarak, modern tasarım ve teknolojileri bir araya getiriyoruz.</p>
                        </div>
                        <div className="hidden md:block">
                            <div className="text-[#008BC9] border border-[#008BC9] hover:bg-black hover:shadow-sm hover:text-white duration-300 rounded-full py-2 group">
                                <Link className="flex items-center gap-3 px-8 py-2" to='/projelerimiz'>
                                    <span>Tüm Projeleri Görüntüle</span>
                                    <div className="duration-300 group-hover:rotate-180">
                                        <ChangeSVG name="arrowoutline" width={24} height={24} color="#008BC9" />
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-6 text-sm font-semibold">
                                Bütün Hizmetlerimiz Hakkında <Link className="text-[#008BC9]" to='/iletisim'>Bize Ulaşın!</Link>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="container pt-16">
                    <BusinessCardList />
                    <Link to='/projelerimiz' className="flex items-center gap-2 mt-12">
                        <ChangeSVG name="arrowoutline" width={24} height={24} color="#1A97CE" />
                        <span className="text-[#1A97CE] font-medium text-lg mb-[2px]">Tüm Projelerimizi Görüntüle</span>
                    </Link>
                </article>
            </section>

        </main>
    )
}

export default HizmetDetay;