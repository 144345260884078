import { Link } from "react-router-dom";
import ChangeIMG from '../helpers/ChangeIMG';


const ContactCard = () => {

    return (
        <div className="contactCard bg-[#FAFAFA] shadow-[_0_0_2px_rgb(0_0_0_/_25%)] rounded-md">
            <div className="flex flex-col overflow-hidden rounded-md md:flex-row">
                <div className="order-1 md:order-0 lg:basis-1/2">
                    <div className="flex flex-col justify-between h-full p-3 lg:p-8">
                        <div>
                            <span className="text-sm">Her Sektörün İhtiyaçlarına Özel Hizmetlerimizi Şekillendiriyoruz!</span>
                            <div className="mt-6 text-3xl italic font-black lg:mt-2">Hemen Teklif Almak İçin Bize Ulaşın</div>
                        </div>
                        <p className="mt-6 opacity-50 lg:mt-0">Size özel çözümlerimizi keşfetmek için bize ulaşın. Profesyonel ekibimiz, ihtiyaçlarınıza uygun tasarlanmış web siteleri, özel yazılım çözümleri ve dijital pazarlama stratejileri sunmak için burada.</p>
                        <div className="mt-4 lg:mt-0">
                            <Link className="inline-flex bg-[#FFC107] hover:bg-[#ffd555] duration-300 py-4 px-6 rounded-md font-semibold" to='/iletisim'>Hemen Teklif Al</Link>
                        </div>
                    </div>
                </div>
                <div className="lg:basis-1/2 order-0 md:order-1">
                    <ChangeIMG page={'anasayfa'} name={'contact'} alt={'Bizimle İletişim Kurun'} />
                </div>
            </div>
        </div>
    )
}

export default ContactCard;